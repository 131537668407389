import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute } from 'react-router-dom'
import appState from "../state/AppState";
import '../scss/components/info-block.scss';
import util from '../util/Util'

@observer
class InfoBlockSwitch extends Component {
    setEnabled(enabled) {
        if (enabled) {
            appState.layoutState.infoBlockEnabled = true;
        } else {
            appState.layoutState.infoBlockEnabled = false;
        }
    }

    render() {

        return (
            <div>
                {(window.location.hostname === 'localhost' || util.getCookie('debug') === "true") &&
                <div className="block-info-buttons row">
                    {window.location.hostname === 'localhost' &&
                    <div>
                        Mostrar archivos:
                        <button type="button" onClick={() => this.setEnabled(true)}>ON</button>
                        <button type="button" onClick={() => this.setEnabled(false)}>OFF</button>
                    </div>
                    }
                </div>
                }
            </div>
        );

    }
}

export default InfoBlockSwitch;

