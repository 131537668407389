import React from 'react';
import { observer } from 'mobx-react';
import FormInputWrapper from "./FormInputWrapper";
import BaseFieldComponent from "./BaseFieldComponent";
import PropTypes from 'prop-types';
import AppLogger from "../../util/AppLogger";
import util from '../../util/Util'
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import * as iconDefs from './iconDefs';

@observer
export default class IconPickerComponent extends BaseFieldComponent {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        readOnly: PropTypes.bool, // Muestra el campo editable, pero con fondo gris en modo read Only
        showView: PropTypes.bool, // Muestra el campo en modo solo texto
        multi: PropTypes.bool, // Muestra el campo en modo solo texto
    };

    static defaultProps = {
        readOnly: false,
        showView: false,
        multi: false,
    };

    constructor(props) {
        super(props);
        this.state = { errorsClientValidation: [], value: "" }
    }

    componentWillReceiveProps(props) {
        if (util.hasValue(props.value)) {
            this.setState({
                value: props.value
            })
        }
    }

    //El autocomplete esta con new-password porque es lo unico que aceptan TODOS
    //los navegadores y la aplicacion no necesita que coja de cache nada
    render() {
        let props = this.props;
        let classNameInput = this.getClassNameInput();
        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                name={props.name}
                postfix={this.props.postfix}
                prefix={this.props.prefix}
                errors={this.props.errors}
                errorsClientValidation={this.state.errorsClientValidation}
                title={this.props.title}
                required={this.props.required}
                info={this.props.info}
                hasNoErrors={this.props.hasNoErrors}
                forceMedia={this.props.forceMedia}
                {...props}
            >
                {this.props.showView ?
                    <div>
                        {props.value}
                    </div>
                    :
                   <>
                       <FontIconPicker
                           icons={iconDefs.fontAwesome.Accessibility}
                           search={iconDefs.fontAwesome.Accessibility}
                           onChange={e => {
                                       let eventObj = {
                                           target: {
                                               name: this.props.name,
                                               value: e
                                           }
                                       };
                                       this.validateOnChangeFunction(eventObj)
                                   }}
                           appendTo="body"
                           value={this.state.value}
                           isMulti
                           theme="bluegrey"
                           renderUsing="data-icomoon"
                       />
                       <p>
                           <small className="text-muted">
                               Current values:{' '}
                                   <span
                                       className="badge badge-light"
                                       key={this.state.value}
                                   >

									</span>
                           </small>
                       </p>
                   </>
                    // <FontIconPicker
                    //     onChange={e => {
                    //         let eventObj = {
                    //             target: {
                    //                 name: this.props.name,
                    //                 value: e
                    //             }
                    //         };
                    //         this.validateOnChangeFunction(eventObj)
                    //     }} icons={iconDefs.icomoonIcons} theme={'bluegrey'}renderUsing="data-icomoon"
                    //     value={this.state.value} search={iconDefs.icomoonIconsSearch}
                    //     isMulti={this.props.multi}
                    // />

                }
                {props.children}
            </FormInputWrapper>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
