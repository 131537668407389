import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";

export default class FetchProxy {

    constructor() {
        this.debug = true;
        this.withAuthorization = false;
    }

    getDefaultHeaders() {
        return {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        };
    }

    /**
     * Manda a fetch una petición POST con el array de elementos que hay en postdataobj.
     * @param url
     * @param postdataobj
     * @param params Se pasan directamente como parametros a fetch
     * @returns {Promise<void>}
     */
    async fetchUrlPost(url, postdataobj, params) {
        //Encode the data
        if (params == null) {
            params = {};
        }
        params["headers"] = this.getDefaultHeaders();
        if (this.withAuthorization) {
            let token = appState.loginState.getAccessToken;
            if (token) {
                params["headers"]["Authorization"] = `Bearer ${token}`;
            }
        }
        params["method"] = "POST";
        params["body"] = JSON.stringify(postdataobj);
        return await this.fetchUrl(url, params);
    }

    /**
     * Manda a fetch una petición GET
     * @param url
     * @param params Se pasan directamente como parametros a fetch
     * @returns {Promise<void>}
     */
    async fetchUrlGet(url, params) {
        //Encode the data
        if (params == null) {
            params = {};
        }
        params["headers"] = this.getDefaultHeaders();
        if (this.withAuthorization) {
            let token = appState.loginState.getAccessToken;
            if (token) {
                params["headers"]["Authorization"] = `Bearer ${token}`;
            }
        }

        params["method"] = "GET";
        return await this.fetchUrl(url, params);
    }

    /**
     * Manda a fetch una petición POST con el array de elementos que hay en postdataobj.
     * @param url
     * @param postdataobj
     * @param params Se pasan directamente como parametros a fetch
     * @returns {Promise<void>}
     */
    async fetchUrlPostUrlEncoded(url, postdataobj, params) {
        //Encode the data
        const searchParams = Object.keys(postdataobj).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(postdataobj[key]);
        }).join('&');
        if (params == null) {
            params = {};
        }
        params["headers"] = this.getDefaultHeaders();
        params["method"] = "POST";
        params["body"] = searchParams;
        return await this.fetchUrl(url, params);
    }

    async fetchUrl(url, params) {
        if (params == null) {
            params = {};
        }
        params["credentials"] = 'same-origin';
        // Nuestro logger se rompe con objetos construidos así:
        // this.log({ obj1, obj2})
        // debe ser this.log({ "obj1": obj1, "obj2": obj2});
        // o this.log(variable);
        // this.log({ url, params });
        let result = await fetch(url, params);
        return result;
    }

    /**
     * Redirige a la home de login si no se tiene permisos. No se usa el metodo. Está para poder usarlo en el futuro
     *
     * @param response
     */
    process401Error(response) {
        if (response.status == 401) {
            window.location.href = "login.php";
        }
    }


    // Logea en consola el mensaje. Sólo útil en depuración
    log(msg) {
        if (this.debug) {
            AppLogger.get().debug(msg, this);
        }
    }


}
