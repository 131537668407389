import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { persistCache } from 'apollo-cache-persist';
import { AsyncStorage } from 'react';
import config from '../config/config';
import appState from '../state/AppState';
const cache = new InMemoryCache();

persistCache({
    cache,
    storage: window.localStorage,
    debug: true,
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, extensions }) =>
            console.log(`[GraphQL Error]: Message: ${message}, Location:${locations} , Path: ${path} , extensions: ${JSON.stringify(extensions)}`))
    }
    if (networkError) {
        console.log(`[GraphQL Network Error]: Message: ${networkError}`);
    }
});

let currentPromiseForRefresh = null;
let evaluatingPromise = false;

// action which happens on relevant error
let recoveryLink = new RetryLink({
    delay: {
        initial: 0,
    },
    attempts: {
        max: 2,
        retryIf: (error, operation) => {
            if (error.statusCode === 401) {
                  if (currentPromiseForRefresh == null && !evaluatingPromise) {
                     evaluatingPromise = true;
                    let promise = new Promise((resolve, reject) => {
                        // your refresh query here
                        appState.loginState.doRefreshToken().then(response => {
                            // do something with response
                            // retry original query
                            currentPromiseForRefresh = null;
                            evaluatingPromise = false;
                            resolve(true);
                        }).catch(() => {
                            currentPromiseForRefresh = null;
                            evaluatingPromise = false;
                            resolve(false);
                        })
                    });
                    currentPromiseForRefresh = promise;
                }
                return currentPromiseForRefresh;
            }
            return false;
        }
    }
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token = appState.loginState.getAccessToken;
    return {
        headers: {
            ...headers,
            "Authorization": token ? `Bearer ${token}` : "",
        }
    }
});

const httpLink = new HttpLink({ uri: config.apiHostBaseUrl });
let link = ApolloLink.from([
        recoveryLink,
        errorLink,
        authLink,
        httpLink,
    ]
);
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'none',
    }
};
const client = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
    defaultOptions,
});

export default client;
