import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Home from "./pages/Home";
import AppWrapper from "./layout/AppWrapper";
import MenuForm from "./pages/menus/MenuForm";
import ErrorBoundary from "./components/ErrorBoundary";
import posed from 'react-pose';
import Page404 from "./pages/Page404";
import SpinnerClipLoaderComponent from "./network/SpinnerClipLoaderComponent";

const MenuList = React.lazy(() => import ("./pages/menus/MenuList"));
const UserList = React.lazy(() => import ("./pages/users/UserList"));
const UserHistory = React.lazy(() => import ("./pages/users/UserHistory"));
const UserForm = React.lazy(() => import ("./pages/users/UserForm"));
const ProfileForm = React.lazy(() => import ("./pages/ProfileForm"));
const PersonList = React.lazy(() => import ("./pages/persons/PersonList"));
const PageList = React.lazy(() => import ("./pages/pages/PageList"));
const PageForm = React.lazy(() => import ("./pages/pages/PageForm"));
const PersonForm = React.lazy(() => import ("./pages/persons/PersonForm"));
const SponsorList = React.lazy(() => import ("./pages/sponsor/SponsorList"));
const SponsorForm = React.lazy(() => import ("./pages/sponsor/SponsorForm"));
const LocationList = React.lazy(() => import ("./pages/locations/LocationList"));
const LocationForm = React.lazy(() => import ("./pages/locations/LocationForm"));
const ConfigTranslates = React.lazy(() => import ("./pages/config/ConfigTranslates"));
const ConfigForm = React.lazy(() => import ("./pages/config/ConfigForm"));
const ScheduleList = React.lazy(() => import ("./pages/shedules/ScheduleList"));
const ScheduleForm = React.lazy(() => import ("./pages/shedules/ScheduleForm"));
const TypifiedValueList = React.lazy(() => import ("./pages/typifieds/TypifiedValueList"));
const TypifiedValueForm = React.lazy(() => import ("./pages/typifieds/TypifiedValueForm"));
const PageDocument = React.lazy(() => import ("./pages/PageDocument"));
const ExhibitorForm = React.lazy(() => import ("./pages/exhibitors/ExhibitorForm"));
const ExhibitorList = React.lazy(() => import("./pages/exhibitors/ExhibitorList"));

const RouteContainer = posed.div({
    enter: {
        y: 0,
        opacity: 1,
        delay: 0,
    },
    visible: {
        opacity: 1,
        transition: { duration: 0 }
    },
    exit: {
        y: 0,
        opacity: 0,
    }
});


/** Permite que al hacer click la página suba al TOP **/

const history = createBrowserHistory();

/** Fin pagina subir a TOP */

/**
 * HashRouter hace que al cambiar las rutas se haga un scroll to TOP.
 * BrowserRouter hace que no haga ningún scroll to top por defecto. Pero podemos hacer que con páginas nuevas si lo haga
 */
@observer
class AppRouter extends Component {
    render() {
        let props = this.props;
        let Try = ErrorBoundary;
        let PoseGroupEmpty = React.Fragment;
        return (
            <Router hashType2='hashbang' history={history}>
                <AppWrapper {...props.children}>
                    <React.Suspense fallback={<SpinnerClipLoaderComponent />}>
                        <Route render={({ location }) => (
                            <PoseGroupEmpty>
                                <RouteContainer key={location.pathname}>
                                    <Switch>
                                        <>
                                            {this.renderMenus().map(Child => Child)}
                                            {this.renderPages().map(Child => Child)}
                                            {this.renderPersons().map(Child => Child)}
                                            {this.renderSponsors().map(Child => Child)}
                                            {this.renderLocations().map(Child => Child)}
                                            {this.renderUsers().map(Child => Child)}
                                            {this.renderExhibitors().map(Child => Child)}
                                            {this.renderSchedules().map(Child => Child)}
                                            {this.renderConfigurations().map(Child => Child)}
                                            {this.renderDocuments().map(Child => Child)}
                                            {this.renderMasters().map(Child => Child)}
                                            <Route exact={true} path="/" render={routeprops => (
                                                <Try><Home key="home" {...routeprops} {...props} /></Try>
                                            )} />
                                            <Route path="/profile/form" key="profile-form" exact={true}
                                                   render={routeprops => (
                                                       <Try><ProfileForm
                                                           key="profile-form" {...routeprops} {...props} /></Try>
                                                   )} />
                                        </>
                                        <Route render={routeprops => (
                                            <Page404 key="page404" {...routeprops} {...props} />
                                        )} />
                                    </Switch>
                                </RouteContainer>
                            </PoseGroupEmpty>
                        )} />
                    </React.Suspense>
                </AppWrapper>
            </Router>
        );
    }

    renderDocuments(props) {
        let Try = ErrorBoundary;
        return [
            <Route path="/person/form/:id?/document" key="person-form-document" exact={true}
                   render={routeprops => (
                       <Try><PageDocument key="asset-form-document"
                                          model="person" {...routeprops} {...props} /></Try>
                   )} />,
            <Route path="/sponsor/form/:id?/document" key="sponsor-form-document" exact={true}
                   render={routeprops => (
                       <Try><PageDocument key="sponsor-form-document"
                                          model="sponsor" {...routeprops} {...props} /></Try>
                   )} />,
            <Route path="/location/form/:id?/document" key="location-form-document" exact={true}
                   render={routeprops => (
                       <Try><PageDocument key="location-form-document"
                                          model="location" {...routeprops} {...props} /></Try>
                   )} />,
            <Route path="/schedule/form/:id?/document" key="schedule-form-document" exact={true}
                   render={routeprops => (
                       <Try><PageDocument key="schedule-form-document"
                                          model="schedule" {...routeprops} {...props} /></Try>
                   )} />,
            <Route path="/exhibitor/form/:id?/document" key="exhibitor-form-document" exact={true}
                   render={routeprops => (
                       <Try><PageDocument key="exhibitor-form-document"
                                          model="exhibitor" {...routeprops} {...props} /></Try>
                   )} />

        ]
    }

    renderConfigurations(props) {
        let Try = ErrorBoundary;
        return [
            <Route path="/config/translates" key="config-translates" exact={true} render={routeprops => (
                <Try><ConfigTranslates
                    key="config-translates" {...routeprops} {...props} /></Try>
            )} />,
            <Route path="/config/form" key="config-validates" exact={true} render={routeprops => (
                <Try><ConfigForm
                    key="config-festives" {...routeprops} {...props} /></Try>
            )} />
        ]
    }

    renderExhibitors() {
        let Try = ErrorBoundary;
        return [
            <Route path="/exhibitor/ls" key="exhibitor-ls" exact={true}
                   render={routeprops => (
                       <Try><ExhibitorList
                           key="exhibitor-ls" {...routeprops} {...this.props} /></Try>
                   )} />,
            <Route path="/exhibitor/form/:id?" key="exhibitor-form" exact={true}
                   render={routeprops => (
                       <Try><ExhibitorForm
                           key="exhibitor-form" {...routeprops} {...this.props} /></Try>
                   )} />
        ]
    }


    renderMenus() {
        let Try = ErrorBoundary;
        return [
            <Route path="/menu/ls" key="menu-ls" exact={true}
                   render={routeprops => (
                       <Try><MenuList
                           key="menu-ls" {...routeprops} {...this.props} /></Try>
                   )} />,
            <Route path="/menu/form/:id?" key="menu-form" exact={true}
                   render={routeprops => (
                       <Try><MenuForm
                           key="menu-form" {...routeprops} {...this.props} /></Try>
                   )} />
        ]
    }

    renderLocations() {
        let Try = ErrorBoundary;
        return [
            <Route path="/location/ls" key="location-ls" exact={true}
                   render={routeprops => (
                       <Try><LocationList
                           key="location-ls" {...routeprops} {...this.props} /></Try>
                   )} />,
            <Route path="/location/form/:id?" key="location-form" exact={true}
                   render={routeprops => (
                       <Try><LocationForm
                           key="location-form" {...routeprops} {...this.props} /></Try>
                   )} />
        ]
    }

    renderSponsors() {
        let Try = ErrorBoundary;
        return [
            <Route path="/sponsor/ls" key="sponsor-ls" exact={true}
                   render={routeprops => (
                       <Try><SponsorList
                           key="sponsor-ls" {...routeprops} {...this.props} /></Try>
                   )} />,
            <Route path="/sponsor/form/:id?" key="sponsor-form" exact={true}
                   render={routeprops => (
                       <Try><SponsorForm
                           key="sponsor-form" {...routeprops} {...this.props} /></Try>
                   )} />
        ]
    }

    renderUsers() {
        let Try = ErrorBoundary;
        return [
            <Route path="/user/ls" key="user-ls" exact={true} render={routeprops => (
                <Try><UserList key="user-ls" {...routeprops} {...this.props} /></Try>
            )} />,
            <Route path="/user/form/:id?/history" key="user-history" exact={true}
                   render={routeprops => (
                       <Try><UserHistory
                           key="user-history" {...routeprops} {...this.props} /></Try>
                   )} />,
            <Route path="/user/form/:id?" key="user-form" exact={true}
                   render={routeprops => (
                       <Try><UserForm key="user-form" {...routeprops} {...this.props} /></Try>
                   )} />
        ]
    }

    renderSchedules() {
        let Try = ErrorBoundary;
        return [
            <Route path="/schedule/ls" key="schedule-ls" exact={true}
                   render={routeprops => (
                       <Try><ScheduleList
                           key="schedule-ls" {...routeprops} {...this.props} /></Try>
                   )} />,
            <Route path="/schedule/form/:id?" key="schedule-form" exact={true}
                   render={routeprops => (
                       <Try><ScheduleForm
                           key="schedule-form" {...routeprops} {...this.props} /></Try>
                   )} />
        ];
    }

    renderPages() {
        let Try = ErrorBoundary;
        return [
            <Route path="/page/ls" key="page-ls" exact={true}
                   render={routeprops => (
                       <Try><PageList
                           key="page-ls" {...routeprops} {...this.props} /></Try>
                   )} />,
            <Route path="/page/form/:id?" key="page-form" exact={true}
                   render={routeprops => (
                       <Try><PageForm
                           key="page-form" {...routeprops} {...this.props} /></Try>
                   )} />
        ];
    }

    renderPersons() {
        let Try = ErrorBoundary;
        return [
            <Route path="/person/ls" key="person-ls" exact={true}
                   render={routeprops => (
                       <Try><PersonList
                           key="person-ls" {...routeprops} {...this.props} /></Try>
                   )} />,
            <Route path="/person/form/:id?" key="person-form" exact={true}
                   render={routeprops => (
                       <Try><PersonForm
                           key="person-form" {...routeprops} {...this.props} /></Try>
                   )} />
        ]
    }

    renderMasters() {
        let Try = ErrorBoundary;

        return [
            <Route path="/typifiedValue/ls" key="typifiedValue-ls" exact={true} render={routeprops => (
                <Try><TypifiedValueList key="typifiedValue-ls" {...routeprops} {...this.props} /></Try>
            )} />,
            <Route path="/typifiedValue/form/:id?" key="typifiedValue-form" exact={true} render={routeprops => (
                <Try><TypifiedValueForm key="typifiedValue-form" {...routeprops} {...this.props} /></Try>
            )} />
        ]

    }

}

export default AppRouter;
