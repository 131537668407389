import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";
import PersonModel from "./PersonModel";

export default class SchedulePersonModel extends AbstractModel {

    scheduleId;
    personId;

    nameMainType = "schedulePerson";
    graphFindByIdOperation = "schedulePerson";
    graphFindOperation = "schedulePersonsConnection";
    graphExportOperation = "schedulePersonsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "scheduleId": "",
            "personId": "",
        };
        this.addRelatedTableDocuments(result, DocumentModel);
        this.addRelatedTablePerson(result, PersonModel);
        return result;
    }


}
