import React, { Component } from 'react';
import FetchProxy from "../network/FetchProxy";
import appState from '../state/AppState';

import config from '../config/config';
import {observer} from "mobx-react/index";
import {Link} from "react-router-dom";

@observer
class Page404 extends Component {


    render(){
        return (
            <section className="container-fluid align-items-center login">
                <h1 className="row justify-content-center">
                    404 Página no econtrada

                </h1>
                <h3>
                    <Link to="/">Volver</Link>
                </h3>
            </section>

        );
    }
}

export default Page404;
