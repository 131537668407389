import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FactoryValidation from "./validations/FactoryValidation";
import AppLogger from "../../util/AppLogger";

@observer
class BaseFieldComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { errorsClientValidation: [], value: this.props.value }
    }

    getClassNameInput() {
        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        let hasErrors = errors.length > 0;
        let classNameInput = "form-control " + this.props.classInput;
        if (hasErrors) {
            classNameInput += " is-invalid text-danger";
        }
        if (this.props.hasNoErrors) {
            classNameInput += " is-valid text-success";
        }
        return classNameInput;
    }


    validateOnChangeFunction(e) {
        let props = this.props;
        let isCorrect = false;
        this.setState({
            value: e.target.value
        });
        if (props.validate) {
            /*
                Las validaciones de formato cómo prevenir que en un campo number haya letras
                tienen que hacerse en el momento.
            * */

            /*recibo una cadena formada por las validaciones a realizar separadas por (|)*/
            let validationList = props.validate.split('|');

            for (let validation of validationList) {

                let args = validation.split(':');
                let validationName = args[0];
                args.shift();
                if (validationName === "decimal") {
                    if (e.target.value[0] === "." || e.target.value === ",") {
                        e.target.value = "0" + e.target.value[0];
                    }
                }
                if (validationName === "numberMinValue") {
                    if (e.target.value < args[0] && e.target.value !== "") {
                        e.target.value = args[0];
                        if (props.onChange != null) {
                            props.onChange(e);
                        }
                    }
                }
                let instanceValidation = FactoryValidation.createFromName(validationName, "change");
                isCorrect = instanceValidation.validate(e.target.name, e.target.value, args);
                if (isCorrect === false) {

                    /*Cuando hay varias validaciones seguidas para el mismo input se va sobrescrbiendo y se queda únicamente
                    * con la última validación.
                    * EJ=> Input = 1 Validamos que sea letra y lengh<2
                    * validacion de letra =false
                    * validacion de length=true
                    * Resultado=> Acaba pintando aunque no cumple TODAS las validaciones
                    * */

                    //Si me encuentro algun false salgo del bucle y me quedo con el false
                    break;
                }
            }
            if (isCorrect) {
                if (props.onChange != null) {
                    props.onChange(e);
                }
            }
        } else {
            if (props.onChange != null) {
                let eventObj = {
                    target: {
                        name: this.props.name,
                        value: e.target.value
                    }
                }
                props.onChange(eventObj);
            }
        }
    }

    validateOnBlurFunction(e) {

        let props = this.props;
        let value = props.value;

        if (value != null) {
            let isCorrect = true;
            if (props.validate) {
                let validationList = props.validate.split('|');
                for (let validation of validationList) {
                    let args = validation.split(':');
                    let validationName = args[0];
                    args.shift();

                    if (validationName === "decimal") {
                        let length = e.target.value.length;
                        if (e.target.value[length - 1] === "." || e.target.value[length - 1] === ",") {
                            e.target.value += "0";
                            if (props.onChange != null) {
                                props.onChange(e);
                            }
                        }
                    }
                    if (validationName === "numberMinValue") {
                        if (e.target.value < args[0]) {
                            e.target.value = args[0];
                            if (props.onChange != null) {
                                props.onChange(e);
                            }
                        }
                    }
                    if (validationName === "required") {
                        let length = e.target.value.length;
                        if (length != 0) {
                            if (props.onChange != null) {
                                props.onChange(e);
                            }
                        }
                    }
                    let instanceValidation = FactoryValidation.createFromName(validationName, "blur");
                    isCorrect = instanceValidation.validate(e.target.name, e.target.value, args);
                }

                if (!isCorrect) {
                    //Mensaje de error
                    this.setState({
                        errorsClientValidation: [{
                            fieldName: e.target.name,
                            message: "Campo no válido"
                        }]
                    });
                } else {
                    this.setState({ errorsClientValidation: [] });
                }
            }
        }

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default BaseFieldComponent;

