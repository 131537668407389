import PhoneValidation from "./PhoneValidation";
import NumberMaxValueValidation from "./NumberMaxValueValidation";
import EmailValidation from "./EmailValidation";
import PostalCodeValidation from "./PostalCodeValidation";
import NumberValidation from "./NumberValidation";
import NumberMinValueValidation from "./NumberMinValueValidation";
import AlphanumericValidation from "./AlphanumericValidation";
import DecimalValidation from "./DecimalValidation";
import MaxLengthValidation from "./MaxLengthValidation";
import RequiredValidation from "./RequiredValidation";

export default class FactoryValidation {

    /**
     *
     * @param nameValidator
     * @param event. Puede ser blur o change
     * @returns {*}
     */
    static createFromName(nameValidator, event) {
        let instance;
        switch(nameValidator) {
            case "alphanumeric":
                instance=new AlphanumericValidation();
                break;
            case "phone":
                instance=new PhoneValidation();
                break;
            case "decimal":
                instance=new DecimalValidation();
                break;
            case "number":
                instance=new NumberValidation();
                break;
            case "numberMaxValue":
                instance=new NumberMaxValueValidation();
                break;
            case "maxLength":
                instance=new MaxLengthValidation();
                break;
            case "numberMinValue":
                instance=new NumberMinValueValidation();
                break;
            case "email":
                instance=new EmailValidation();
                break;
            case "postalCode":
                instance=new PostalCodeValidation();
                break;
            case "required":
                instance=new RequiredValidation();
                break;

            default:
                // instance=new BaseValidation();
                break;
        }
        instance.event=event;
        return instance;
    }

}
