import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import SidebarItem from "./SidebarItem";
import SidebarSubItem from "./SidebarSubItem";
import appState from '../state/AppState'
import withTranslate from "../translator/withTranslate";
import AppLogger from "../util/AppLogger";


@observer
class Sidebar extends Component {

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    ifPathIsDashboard(pathMatch) {
        let result = false;
        if (pathMatch.length == '1') {
            result = true;
        }
        return result;
    }

    openCloseItemsMenuClients = () => {
        appState.layoutState.clickHiddenMenuClients();
    };

    render() {
        let props = this.props;
        const { t, i18n } = this.props;

        return (

            <div className="sidebar">
                <nav className="sidebar-nav">
                    <ul className="nav">

                        <SidebarItem href="/" icon="fas fa-door-open" title={t("Panel de Control")}
                                     active={this.ifPathIsDashboard(this.props.location.pathname)} />
                        <SidebarItem href="/menu/ls" icon="fas fa-bars" title={t("Menus")}
                                     active={this.ifPathStartsWith("/menu/ls")} />
                        <SidebarItem href="/page/ls" icon="fas fa-file-word" title={t("Páginas")}
                                     active={this.ifPathStartsWith("/page/ls")} />
                        <SidebarItem href="/person/ls" icon="fas fa-users" title={t("Personas")}
                                     active={this.ifPathStartsWith("/person")} />
                        <SidebarItem href="/sponsor/ls" icon="fas fa-users" title={t("Sponsors")}
                                     active={this.ifPathStartsWith("/sponsor")} />
                        <SidebarItem href="/exhibitor/ls" icon="fas fa-map-marker-alt" title={t("Expositores")}
                                     active={this.ifPathStartsWith("/exhibitor")} />
                        <SidebarItem href="/location/ls" icon="fas fa-map-marker-alt" title={t("Localización")}
                                     active={this.ifPathStartsWith("/location")} />
                        <SidebarItem href="/schedule/ls" icon="fas fa-calendar" title={t("Agenda")}
                                     active={this.ifPathStartsWith("/schedule")} />
                        <SidebarItem href="/user/ls" icon="fas fa-users" title={t("Usuarios")}
                                     active={this.ifPathStartsWith("/user")} />
                        <SidebarItem id='maestros' href="/typifiedValue/ls?type=zone" icon="fas fa-list"
                                     active={this.ifPathStartsWith("/typifiedValue")} title={t("Maestros")} />
                    </ul>
                </nav>
            </div>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}


export default withTranslate(withRouter(Sidebar));

