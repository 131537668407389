import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link, withRouter } from "react-router-dom";
import appState from "../state/AppState";
import AlertModal from "../subpages/AlertModal";
import PopUp from "./modals/PopUp";
import PropsUtil from "../util/PropsUtil";
import AppLogger from "../util/AppLogger";
import PropTypes from 'prop-types';
import withTranslate from "../translator/withTranslate";
import SpinnerClipLoaderComponent from "../network/SpinnerClipLoaderComponent";

@observer
class VsfLink extends Component {

    static defaultProps = {
        disabled: false
    }

    static propTypes = {
        disabled: PropTypes.bool, //No se pone como enlace porque está deshabilitado
    }

    constructor(props) {
        super(props);
        this.state = {
            modalChangeOpen: false,
            modalLoading: false,
        }
    }

    onClick(e) {
        let result = this.comprobarCambiosFormulario(e);
        if (result) {
            if (this.props.onClick) {
                this.props.onClick(e);
            }
        }
        return result;
    }

    comprobarCambiosFormulario(e) {
        let result = true;
        if (appState.layoutState.loadingForm) {
            e.preventDefault();
            this.setState({
                modalLoading: true,
            });
            setTimeout(() => {
                this.propsUtil.changeUrl(this.props.to);
                this.setState({
                    modalLoading: false,
                });
            }, 1500);
        } else if (!appState.layoutState.formWithoutChanges) {
            e.preventDefault();
            result = false;
            this.setState({
                modalChangeOpen: true,
            });
        }

        return result;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!appState.layoutState.loadingForm) {
            this.setState({
                modalLoading: false,
            });
        }

    }


    cancelChangeUrl() {
        this.setState({
            modalChangeOpen: false,
            modalLoading: false,
        });
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    saveChange() {
        this.setState({
            modalChangeOpen: false,
        });
        if (this.props.onClick) {
            this.props.onClick();
        }

    }

    continueChangeUrl(location) {
        appState.layoutState.formWithoutChanges = true;
        if (this.props.onClick) {
            this.props.onClick();
        }
        this.setState({
            modalChangeOpen: false,
        });
        this.propsUtil.changeUrl(this.props.to)
    }

    render() {
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        return (
            <>
                {!this.props.disabled &&
                <>
                    <Link
                        to={this.props.to}
                        className={this.props.className}
                        onClick={(e) => this.onClick(e)}
                    >

                        {this.props.children}
                    </Link>
                    {this.state.modalLoading &&
                    <SpinnerClipLoaderComponent />
                    }

                    {this.state.modalChangeOpen &&
                    <AlertModal isOpen={this.state.modalChangeOpen}
                                onCloseModal={() => this.cancelChangeUrl()}
                    >
                        <PopUp
                            title={t('Estás saliendo sin guardar')}
                            icon="exclamationTriangleIcon"
                            text={t('Has hecho cambios en el formulario, y no han sido guardados. ¿Seguro que quieres salir?')}
                            label1={'Salir sin guardar'}
                            label2={'Volver a edición'}
                            labelGuardar={true}
                            onClick1={() => this.continueChangeUrl()}
                            onClick2={() => this.cancelChangeUrl()}
                        />
                    </AlertModal>
                    }


                </>
                }
                {this.props.disabled &&
                <a className={this.props.className} href="#" onClick={(e) => e.preventDefault()}>
                    {this.props.children}
                </a>
                }

            </>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(withRouter(VsfLink));

