import React from 'react';
import withTranslate from "../../translator/withTranslate";

const ErrorsFromGql = (props) => {
    const { t } = props;

    return (
        <>
            {props.errorsMapped[""] != null ?
                <div className="alert alert-danger" role="alert">
                    {props.errorsMapped[""].map(error =>
                        <div>{t(error.message)}</div>
                    )}
                </div>
                :
                null
            }
        </>
    )
}

export default withTranslate(ErrorsFromGql);
