import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import util from "../util/Util";
import UserModel from "../models/UserModel"

class UserState extends BaseModelState {
    @observable users = [];


    async getUserById(userId) {
        let userQuery = new UserModel();
        let result=null;
        if (util.hasValue(userId)) {
            result = await userQuery.findById(userId);
        }
        return result;
    }


}

export default UserState;
