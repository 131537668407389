import BaseModelState from "./BaseModelState";
import { computed, observable } from 'mobx';
import PageModel from "../models/PageModel";

class PageState extends BaseModelState {

    @observable pages = [];

    async loadAllPages() {
        let pageModel = new PageModel();
        this.pages = await pageModel.find();
    }

}

export default PageState;
