import util from "./Util";
import AppLogger from "./AppLogger";

export default class Links {

    constructor(location) {
        this.location = location;
    }

    /**
     * A partir de la URL cambia el/los parametros identificado en el el objeto parametersObject
     * orderObject => {order:"XX"}
     * @param parametersObject
     * @returns {string}
     */
    getUrlLink(parametersObjectParam) {
        let resultArr = [];

        let parametersObject = parametersObjectParam;
        parametersObject.after = null;
        parametersObject.before = null;

        const pathname = this.location.pathname;
        resultArr.push(pathname + "?");
        const params = new URLSearchParams(this.location.search);

        let i = 0;
        for (let param of params) {
            let name = param[0];
            let value = param[1];

            if (parametersObject[name] != null) {
                value = parametersObject[name];
                parametersObject[name] = null;
            } else if (parametersObject[name] === null) {
                value = null;
            }
            if (value === null) {

            } else {
                if (i !== 0) {
                    resultArr.push("&");
                }
                resultArr.push(name);
                if (value != "") {
                    resultArr.push("=" + value);
                }
                i++;
            }
        }
        for (let key of Object.keys(parametersObject)) {
            let name = key;
            let paramValue = parametersObject[key];
            if (paramValue != null) {
                let value = encodeURIComponent(parametersObject[key]);
                if (i !== 0) {
                    resultArr.push("&");
                }
                resultArr.push(name);
                if (value != "") {
                    resultArr.push("=" + value);
                }
                i++;
            }
        }
        const result = resultArr.join("");
        return result;
    }

    /**
     * Devuelve la URL sin filters en la url
     * @returns {string}
     */
    getUrlLinkWithoutFilters() {
        let resultArr = [];

        let parametersObject = {};
        parametersObject.after = null;
        parametersObject.before = null;

        const pathname = this.location.pathname;
        resultArr.push(pathname + "?");
        const params = new URLSearchParams(this.location.search);
        let i = 0;

        for (let param of params) {
            let name = param[0];
            let value = param[1];
            if (name != "filters" && value != null) {
                if (i++ !== 0) {
                    resultArr.push("&");
                }
                resultArr.push(name);
                if (value != "") {
                    resultArr.push("=" + value);
                }
            }
        }
        return resultArr.join("");
    }

    getUrlLinkPageNext(after) {
        const params = new URLSearchParams(this.location.search);
        let page = parseInt(params.get("page"));
        if (isNaN(page)) {
            page = 1;
        }
        if (page === 0) {
            page = 1;
        }
        page++;

        let result = this.getUrlLink({ after, before: null, page });
        return result;
    }

    getUrlLinkPagination(pagination) {
        let result = this.getUrlLink({ pagination });
        return result;
    }

    /**
     * Devuelve un array de elementos indexados por key
     * @returns ["key": {key: "", name:"", value:"", op:""}]
     */
    getCurrentFilterFromUrl() {
        let result = {};
        let search = {};
        if (this.location !== undefined) {
            search = this.location.search;
        }
        const params = new URLSearchParams(search);
        let filters = params.get("filters");
        let filterArray = JSON.parse(filters);
        if (filterArray == null) {
            filterArray = [];
        }
        for (let filterObj of filterArray) {
            /* filterObj es de de la forma {"fieldName":"priority","fieldValue":"P02"} */
            let fieldNameToQuery = util.getDelim(filterObj.fieldName, "_", 0);
            result[filterObj.fieldKey] = {
                key: filterObj.fieldKey,
                name: filterObj.fieldName,
                value: filterObj.fieldValue,
                label: filterObj.fieldLabel,
                op: filterObj.filterOperator
            };
        }
        return result;
    }

    getUrlLinkWithFilters(filterArray) {
        const params = new URLSearchParams(this.location.search);
        let filters = params.get("filters");
        filters = JSON.stringify(filterArray);
        let before = null;
        let after = null;
        let page = null;
        let result = this.getUrlLink({ after, before, page, filters });
        return result;
    }

    getUrlLinkPageNumber(number) {
        const params = new URLSearchParams(this.location.search);
        let page = parseInt(number);
        if (isNaN(page)) {
            page = 1;
        }
        if (page === 0) {
            page = 1;
        }

        let result = this.getUrlLink({ after: null, before: null, page });
        return result;
    }

    getUrlLinkPagePrev(before) {
        const params = new URLSearchParams(this.location.search);
        let page = parseInt(params.get("page"));
        if (isNaN(page)) {
            page = 1;
        }
        if (page === 0) {
            page = 1;
        }
        page--;

        let result = this.getUrlLink({ after: null, before, page });
        return result;
    }

    getUrlLinkPageFirst() {
        let result = this.getUrlLink({ after: null, before: null, page: null });
        return result;
    }

    getUrlLinkPageLast() {
        let result = this.getUrlLink({ after: null, before: null, page: null });
        return result;
    }

    getUrlLinkOrder(newFieldOrder) {
        const params = new URLSearchParams(this.location.search);
        const previousOrder = params.get("order");
        let previousOrderMode = params.get("orderMode");
        if (previousOrderMode === null) {
            previousOrderMode = "ASC";
        }
        let newOrderMode = "ASC";
        if (previousOrder == newFieldOrder) {
            if (previousOrderMode === "ASC") {
                newOrderMode = "DESC";
            }
        }
        let result = this.getUrlLink({
            order: newFieldOrder,
            orderMode: newOrderMode,
            after: null,
            before: null,
            page: null
        });
        return result;
    }


    /**
     * Devuelve el id: String del formulario
     */
    getFormId(props) {
        let id = "";
        try {
            id = props.match.params.id;
            if (id == null) {
                id = props.id;
            }
        } catch (e) {
        }
        if (id != null) {
            //Fuerzo que sea cadena
            id = "" + id;
        }
        return id;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
