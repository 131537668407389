import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";
export default class PersonModel extends AbstractModel {

    name;
    lastName;
    position;
    charge;
    institution;
    curriculum;
    nameMainType = "person";
    graphFindByIdOperation = "person";
    graphFindOperation = "personsConnection";
    graphExportOperation = "personsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "name": "",
            "lastName": "",
            "position": "",
            "charge": "",
            "institution": "",
            "curriculum": "",
        };
        this.addRelatedTableDocuments(result, DocumentModel);
        return result;
    }


}
