import React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import PropTypes from 'prop-types'
import FormInputWrapper from "./FormInputWrapper";
import util from "../../util/Util";
import BaseFieldComponent from "./BaseFieldComponent";
import AppLogger from "../../util/AppLogger";

const options = [];


export default class Select2Component  extends BaseFieldComponent {

    static defaultProps = {
        onChange: _ => 0, //Evento al cambiar el elemento
        value: "", // Value of the input
        name: "", // Name of the input
        title: "", // Title of the element. Showed in the label
        classGroup: "", // CSS class
        options: [], // List of elements to show in the select [{"label":"Label", value:"1"}]
        isClearable: true,
        optionsWrappedWithCommas: true,
        forceMedia: null, // Fuerza a que se pinte con un media concreto. Por ejemlo md.
    }

    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        classGroup: PropTypes.string,
        options: PropTypes.array,
        isClearable: PropTypes.bool,
        optionsWrappedWithCommas: PropTypes.bool,
        forceMedia: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            selectedOption: null,
        };
    };

    componentDidMount() {
        this.setState({
            selectedOption: this.props.value,
        });

    }

    updateInputEvent(event) {
        this.updateInput(event.target.name, event.target.value)
    }

    getOptionsKeyed(options) {
        let result = {};
        for (let item of this.props.options) {
            result[item.value] = item;
        }
        return result;
    }

    onBlurFunction(e) {
        if (this.props.onBlur != null) {
            this.props.onBlur(e)
        } else {
            this.validateOnBlurFunction(e)
        }
    }



    componentWillReceiveProps(nextProps) {
        console.log("Select2Component.componentWillReceiveProps()" + this.props.value + " - " + nextProps.value);
        if (nextProps.value !== this.props.value) {
            this.reload(nextProps.value);
        }
    }

    async reload(value) {
        this.setState({
            selectedOption: value
        })
    }

    handleChange(selectedOption) {

        if (this.props.multi) {
            let values = "";
            let labels = "";

            if (this.props.optionsWrappedWithCommas) {
                selectedOption.map((option) => {
                    if (values != "") {
                        values = values + ",'" + option.value + "'";
                        labels = labels + ",'" + option.label + "'";
                    } else {
                        values = "'" + option.value + "'";
                        labels = "'" + option.label + "'";
                    }
                });
            } else {

                // Si el Select Multi tiene como filtro IN, STRIN o NULL (que por defecto sera IN), encomillamos la label para mostrarla correctamente
                if(this.props.operatorFilter == null || this.props.operatorFilter == "IN" || this.props.operatorFilter == "STRIN"){
                    selectedOption.map((option) => {
                        if (values != "") {
                            values = values + "," + option.value + "";
                            labels = labels + ",/'" + option.label + "/'";
                        } else {
                            values = "" + option.value + "";
                            labels = "/'" + option.label + "/'";
                        }
                    });
                } else {
                    selectedOption.map((option) => {
                        if (values != "") {
                            values = values + "," + option.value + "";
                            labels = labels + "," + option.label + "";
                        } else {
                            values = "" + option.value + "";
                            labels = "" + option.label + "";
                        }
                    });
                }

            }

            this.setState({ selectedOption });

            if (this.props.onChange != null) {
                if (this.props.operatorFilter == null) {
                    this.props.onChange({ target: { name: this.props.name, value: values, label: labels } }, "IN");
                } else {
                    this.props.onChange({
                        target: {
                            name: this.props.name,
                            value: values,
                            label: labels
                        }
                    }, this.props.operatorFilter);
                }

            }

        } else {
            let selectedOptionsValue;
            let selectedOptionsLabel;
            if (util.esVacio(selectedOption)) {
                selectedOptionsValue = "";
                this.setState({
                    selectedOption: null
                });
            } else {
                this.setState({
                    selectedOption: selectedOption.value
                });
                console.log(`Select2Component. Option selected:`, selectedOption);
                console.log(this.state);
                console.log(this.props.name);
                selectedOptionsValue = selectedOption.value;
                selectedOptionsLabel = selectedOption.label;
            }
            if (this.props.onChange != null) {
                this.props.onChange({
                    target: {
                        name: this.props.name,
                        value: selectedOptionsValue,
                        label: selectedOptionsLabel
                    }
                });
            }
        }

    };

    getValueOptionMulti = (options, dict) => {

        let response = [];

        if (options == null) {
            options = "";
        }
        let optionsArray = options.split(",");
        optionsArray.map((option) => {
            if (this.props.optionsWrappedWithCommas == true) {
                response.push(dict[option.slice(1, -1)]);
            } else {
                response.push(dict[option]);
            }

        });
        return response;

    };

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    /**
     * Metodo que filtra de forma personalizada
     */
    customFilter(candidate, input) {
        // Pasamos las variables a minuscula para no distinguir entre minuscula y mayuscula
        let inputLowerCase = input.toLowerCase();

        let label = "";
        if (candidate.label != undefined && typeof candidate.label == "string") {
            label = candidate.label.toLowerCase();
        }
        if (candidate.label.props && candidate.label.props.search != undefined && typeof candidate.label.props.search == "string") {
            label = candidate.label.props.search.toLowerCase();
        }
        let value = "";
        if (candidate.value != undefined && typeof candidate.value == "string") {
            value = candidate.value.toLowerCase();
        }

        let q = "";
        if (candidate.q != undefined && typeof candidate.q == "string") {
            q = candidate.q.toLowerCase();
        }

        // Si tenemos algun input comprobamos los filtros
        if (input) {
            return q.includes(inputLowerCase) || value.includes(inputLowerCase) || label.includes(inputLowerCase);
        }

        return true;

    };

    render() {
        let props = this.props;
        let form = this.form;
        if (form == null) {
            form = {};
        }

        const { selectedOption } = this.state;

        //let label = this.props.options.filter((item)=> item.value==this.props.value)[0];
        let optionsKeyed = this.getOptionsKeyed(options);
        let valueOption = "";

        if (this.props.multi) {
            valueOption = this.getValueOptionMulti(this.props.value, optionsKeyed);
        } else {
            valueOption = optionsKeyed[this.props.value];
        }

        return (
            <>
                <FormInputWrapper
                    classGroup={props.classGroup}
                    classInputType={"Select2Component"}
                    name={props.name}
                    postfix={this.props.postfix}
                    prefix={this.props.prefix}
                    errors={this.props.errors}
                    title={this.props.title}
                    errorsClientValidation={this.state.errorsClientValidation}
                    required={this.props.required}
                    isClearable={this.props.isClearable}
                    info={this.props.info}
                    forceMedia={this.props.forceMedia}
                >
                    {this.props.showView ?
                        <div>
                            {props.value}
                        </div>
                        :
                        <Select
                            placeholder={props.placeholder || "Seleccionar"}
                            value={valueOption}
                            onChange={(e) => this.handleChange(e)}
                            clearable={props.clearable}
                            options={props.options}
                            onBlur={e => this.onBlurFunction(e)}
                            multi={this.props.multi}
                            filterOption={(candidate, input) => this.customFilter(candidate, input)}
                        />
                    }
                    {/*Ya se pinta en los formularios*/}
                    {/*{errors.map(error => <small className="js-error help-block text-danger">{error.message}</small>)}*/}

                </FormInputWrapper>
            </>
        );
    }
}
