import AbstractModel from "./AbstractModel";
import TypifiedValueModel from "./TypifiedValueModel";

export default class UserModel extends AbstractModel {
    address;
    birthDate;
    city;
    company;
    contactPerson;
    contactPhoneNumber;
    contactRelationship;
    country;
    email;
    employeeNumber;
    firstName;
    status;
    id;
    idNumber;
    companyPhoneNumber;
    lastName;
    locale;
    mobileNumber;
    password;
    phoneNumber;
    employment;
    postalCode;
    province;
    insuranceNumber;
    capacitationIds;
    role;
    stockControl;
    citizenNumber;
    subInventory;
    userVehicle;
    timeZone;
    zones;
    zoneAsignedId;
    nameMainType = "user";
    graphFindByIdOperation = "user";
    graphFindOperation = "usersConnection";
    graphExportOperation = "usersExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "address": { label: 'Direccion' },
            "birthDate": { label: 'Fecha de Nacimiento' },
            "city": { label: 'Ciudad' },
            "company": { label: "Empresa" },
            "contactPerson": { label: 'Persona de contacto' },
            "contactPhoneNumber": { label: 'Telefono de Contacto' },
            "contactRelationship": { label: 'Relacion' },
            "companyEmail": { label: "Email de empresa" },
            "country": { label: 'Pais' },
            "email": "",
            "employeeNumber": { label: "Número de empleado" },
            "employment": { label: "Puesto de trabajo" },
            "firstName": { label: 'Nombre' },
            "idNumber": { label: 'DNI' },
            "lastName": { label: 'Apellido' },
            "citizenNumber": { label: "Número de ciudadano" },
            "insuranceNumber": { label: "Número de la SS" },
            "locale": "",
            "mobileNumber": { label: "Número de móvil" },
            "password": "",
            "phoneNumber": { label: 'Numero de Telefono' },
            "postalCode": { label: 'Código Postal' },
            "province": { label: 'Provincia' },
            "role": { label: "Rol" },
            "stockControl": { type: "BoolField" },
            "subInventory": "",
            "companyPhoneNumber": { label: "Número de empresa" },
            "capacitationIds": "",
            "timeZone": "",
            "userVehicle": { label: "Vehículo" },
            "status": { label: "Estado" },
            "zoneAsignedId": { label: "Zona de trabajo" },
        };
        this.addRelatedTableZoneAsigned(result, TypifiedValueModel);
        this.addRelatedTableRelationship(result, TypifiedValueModel);
        this.addRelatedTableEmployment(result, TypifiedValueModel);


        return result;
    }

};
