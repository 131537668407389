import React, { Component } from 'react';
import appState from '../state/AppState';
import Login from "./Login";
import Dashboard from "./dashboard/Dashboard";

class Home extends Component {

    render() {
        let userLogged = appState.loginState.isUserLogged;
        if (!userLogged) {
            return (
                <Login />
            )
        } else {
            return (
                <Dashboard />
            );
        }
    }
}

export default Home;
