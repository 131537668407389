import {observable} from 'mobx';
import AppLogger from "../util/AppLogger";
import util from "../util/Util";
import BaseModelState from "./BaseModelState";
import TypifiedValueModel from "../models/TypifiedValueModel";

class TypifiedState extends BaseModelState {

    /**
     * Indexado por el id del maestro contiene todos los objetos que tengo cargados.
     * @type {{}}
     */
    @observable allObjectsById = {};

    /**
     * Id del elemento que esta siendo editado en el formulario
     * @type {number}
     */
    @observable formIdTypifiedValue = 0;
    /**
     * Indexado por el tipo de campo contiene un array de los maestros
     * @type {{}}
     */
    @observable arrWithType = {};

    /**
     * Obtiene el array de typifed para select jerarquico piramidal creando el arbol e iterando en preorden posteriormente
     * @returns {Array}
     */
    typifiedValuesForSelectPiramidal(type) {
        this.log("typifiedValuesForSelectPiramidal() =>");
        let typifiedValuesHierarchyArray = []
        if (this.arrWithType[type] != null) {
            // Primero, obtiene el arbol ordenado de los typifed
            let typifiedValuesHierarchy = this.getTypifiedValuesHierarchy(this.arrWithType[type]);
            // Segundo, obtenemos el array en orden
            typifiedValuesHierarchyArray = this.getTypifiedValuesHierarchyArray(typifiedValuesHierarchy);
        }
        return typifiedValuesHierarchyArray;
    }

    /**
     * Obtiene el array de typifed para listas jerarquicas creando el arbol e iterando en preorden posteriormente
     * @returns {Array}
     */
    typifiedValuesForList(type) {
        let typifiedValuesHierarchyArray = [];
        this.log({typifiedValuesForList: 1, lista: this.arrWithType});
        if (this.arrWithType[type] != null) {
            // Primero, obtiene el arbol ordenado de los typifed
            let typifiedValuesHierarchy = this.getTypifiedValuesHierarchy(this.arrWithType[type]);
            // Segundo, obtenemos el array en orden
            typifiedValuesHierarchyArray = this.getTypifiedValuesHierarchyArray(typifiedValuesHierarchy, false, true);
        }
        return typifiedValuesHierarchyArray;
    }


    /**
     * Metodo que recorre los nodos padres para llamar recursivamente al metodo que creara los hijos
     * @param nodos
     * @returns {Array}
     */
    getTypifiedValuesHierarchy(nodos) {
        let result = [];
        this.log({typifiedValuesForList: 1, nodos});
        let nodosLibres = nodos.slice(0);
        for (let nodo of nodos) {
            // Si es un nodo padre, construimos el arbol hijo
            if (this.isParent(nodo, nodos)) {
                // Quitamos el nodo padre de la lista de nodos libres
                nodosLibres = nodosLibres.filter(function (obj) {
                    return obj.id !== nodo.id;
                });
                result.push({
                    id: nodo.id,
                    code: nodo.code,
                    value: nodo.value,
                    parentId: nodo.parentId,
                    childs: this.getTypifiedValuesHierarchyChilds(nodo.id, nodosLibres)
                })
            }
        }
        return result;
    }

    /**
     * Metodo que a partir de un nodo padre, busca nodos hijos y los coloca recursivamente
     * @param parentId
     * @param nodos
     * @returns {Array}
     */
    getTypifiedValuesHierarchyChilds(parentId, nodos) {
        let childs = [];
        // Estos seran los nodos libres
        let nodosLibres = nodos.filter(function (obj) {
            return obj.parentId != parentId;
        });
        for (let nodo of nodos) {
            // Si es un nodo hijo, lo agregamos al array de hijos
            if (nodo.parentId == parentId) {
                childs.push({
                    id: nodo.id,
                    code: nodo.code,
                    value: nodo.value,
                    parentId: nodo.parentId,
                    childs: this.getTypifiedValuesHierarchyChilds(nodo.id, nodosLibres)
                })
            }
        }
        return childs;
    }

    /**
     * A partir del arbol ordenado de typified, obtiene el array en preorden recursivamente para ser mostrado en el select
     * @param nodos
     * @param piramidal
     * @param list
     * @param altura
     * @param disabled
     * @param label
     *
     * @returns {Array}
     */
    getTypifiedValuesHierarchyArray(nodos, piramidal = true, list = false, altura = 0, disabled = false, label = "") {
        let result = [];
        for (let nodo of nodos) {
            // Si es el mismo nodo del formulario, el y sus hijos no podran ser seleccionados como padres
            if (nodo.id == this.formIdTypifiedValue) {
                disabled = true;
            }
            let newLabel = label;
            // Añadimos el nodo padre al array de retorno dependiendo si es para una lista o un select
            if (list) {
                result.push({
                    id: nodo.id,
                    code: nodo.code,
                    value: nodo.value,
                    label: this.getTypifiedValueHierarchyLabel(nodo.code, altura, true),
                    parent: nodo.parentId,
                    altura,
                    disabled
                });
            } else {
                // Distinguimos entre select piramidal o lineal
                if (piramidal) {
                    result.push({
                        id: nodo.id,
                        code: nodo.code,
                        value: nodo.id,
                        label: this.getTypifiedValueHierarchyLabel(nodo.value, altura),
                        parent: nodo.parentId,
                        altura,
                        disabled
                    });
                } else {
                    // Si es un nodo padre, en un selector lineal no lo incluimos, unicamente creamos la label para pasarla a sus hijos
                    if (newLabel == "") {
                        newLabel = nodo.value;
                    } else {
                        newLabel += " → " + nodo.value;
                        result.push({
                            id: nodo.id,
                            code: nodo.code,
                            value: nodo.id,
                            label: newLabel,
                            parent: nodo.parentId,
                            altura,
                            disabled
                        });
                    }
                }
            }
            // Si el nodo tiene hijos, los añadimos al array despues de este elemento sumando uno a la altura
            if (nodo.childs.length != 0) {
                let childs = this.getTypifiedValuesHierarchyArray(nodo.childs, piramidal, list, altura + 1, disabled, newLabel);
                result = result.concat(childs);
            }
            // Desactivamos el disabled para los hermanos
            if (nodo.id == this.formIdTypifiedValue) {
                disabled = false;
            }
        }

        return result;

    }

    /**
     * Obtiene la etiqueta con guiones para una opcion de un selector jerarquico o una lista jerarquica
     * @param value
     * @param altura
     * @param list
     * @returns {string}
     */
    getTypifiedValueHierarchyLabel(value, altura, list = false) {
        let separacion = "";
        let separador = " - ";
        if (list) {
            separacion = "";
        } else {
            for (let i = 0; i < altura; ++i) {
                separacion += separador;
            }
            separacion += " ";
        }
        return separacion + value;
    }

    /**
     * Obtiene si el tipo de typifed value se puede ordenar jerarquicamente
     * @returns {boolean}
     */
    isTypifiedValueHierarchy(type) {
        let result = false;
        if (type === "workOrderType" || type === "assetType") {
            result = true;
        }
        return result;
    }

    async loadMasters() {
        let listTypes = [
            TypifiedValueModel.CODE_USER_ROLE,
            TypifiedValueModel.CODE_SPONSOR_TYPE,
            TypifiedValueModel.CODE_RELATIONSHIP,
            TypifiedValueModel.CODE_ZONE,
            TypifiedValueModel.CODE_EMPLOIMENTS,
            TypifiedValueModel.CODE_EMPLOYEE_CAPACITATION,
            TypifiedValueModel.CODE_LOCALES,
            TypifiedValueModel.CODE_TAGS,
            TypifiedValueModel.CODE_TIME_ZONES,
            TypifiedValueModel.CODE_SCHEDULE_TYPE,
            TypifiedValueModel.CODE_PARTICIPATION_TYPE,
        ];

        let typeQuery = new TypifiedValueModel();
        typeQuery.filters = [
            {"fieldName": "type", "fieldValue": listTypes.join(","), "filterOperator": "STRIN"},
        ];
        let rows = await typeQuery.find();
        let newDict = {};
        for (let row of rows) {
            if (newDict[row.type] == null) {
                newDict[row.type] = [];
            }
            newDict[row.type].push(row);
            this.allObjectsById[row.id] = row;
        }
        for (let type of listTypes) {
            this.arrWithType[type] = newDict[type];
        }
    }

    getObject(id) {
        let result = this.allObjectsById[id];
        if (result == null) {
            result = new TypifiedValueModel();
        }
        return result;
    }

    getObjectFromCode(masterType, code) {
        let result = this.arrWithType[masterType] ? this.arrWithType[masterType].find(e => e.code == code) : null;
        if (result == null) {
            result = new TypifiedValueModel();
        }
        // this.log({ getObjectFromCode: 1, result, assWithType: this.arrWithType[masterType] });
        return result;
    }

    getValue(id) {
        return this.getObject(id).value;
    }

    getCode(id) {
        return this.getObject(id).code;
    }

    isParent(nodo, arrNodos) {
        let result;
        if (util.esVacio(nodo.parentId) || nodo.parentId === util.getZeroIdIdentifierGUID()) {
            result = true;
        }
        return result;
    }

    getLabelValuesForSelect(masterType) {
        let result = [];
        if (this.arrWithType[masterType]) {
            for (let typified of this.arrWithType[masterType]) {
                if (this.isParent(typified, this.arrWithType[masterType])) {
                    result.push({value: typified.id, label: typified.value})
                }
            }
        }
        return result;
    }
    getLabelLabelForSelect(masterType) {
        let result = [];
        if (this.arrWithType[masterType]) {
            for (let typified of this.arrWithType[masterType]) {
                if (this.isParent(typified, this.arrWithType[masterType])) {
                    result.push({value: typified.value, label: typified.value})
                }
            }
        }
        return result;
    }

    getLabelValuesForSelectChilds(masterType, parentId) {
        let result = [];
        if (this.arrWithType[masterType]) {
            for (let assetType of this.arrWithType[masterType]) {
                if (assetType.parentId == parentId) {
                    result.push({value: assetType.id, label: assetType.value})
                }
            }
        }
        return result;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default TypifiedState;
