import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';


const customStyles = {
    overlay: {

        backgroundColor: "rgba(0,0,0,0.5)",
    }
};

@observer
export default class VsfModal extends Component {

    constructor(props) {
        super(props);
    }


    openModal() {
        if (this.props.stateFilter) {
            this.props.stateFilter.modalOpen = true;
        }
    };

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    };

    closeModal() {
        if (this.props.onCloseModal != null) {
            this.props.onCloseModal();
        } else {
            if (this.props.stateFilter) {
                this.props.stateFilter.modalOpen = false;
            }
        }
    }

    render() {
        let isOpen = this.props.isOpen;
        if (isOpen == null) {
            if (this.props.stateFilter) {
                isOpen = this.props.stateFilter.modalOpen;
            }
        }
        return (
            <Modal
                isOpen={isOpen}
                onAfterOpen={_ => this.afterOpenModal()}
                onRequestClose={() => this.closeModal()}
                className={this.props.className}
                contentLabel="Example Modal"
                closeTimeoutMS={200}
                ariaHideApp={false}
                style={customStyles}
            >
                {this.props.children}
            </Modal>
        );
    }
}
