import AbstractModel from "./AbstractModel";

export default class TranslationsModel extends AbstractModel {

    static WEB = "WEB";
    static MOBILE = "MOBILE";
    static ALL = "ALL";

    id;
    label;
    message;
    lang;
    device;


    nameMainType = "translation";
    graphFindByIdOperation = "translation";
    graphFindOperation = "translationsConnection";

    getArrayFields() {
        let result = {
            "id": { type: "CodeField" },
            "label": "",
            "message": "",
            "device": "",
            "lang": "",
        };
        return result;
    }


}

