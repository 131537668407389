import Model from "./Model";

export default class AbstractModel extends Model {

    updatedAt;
    updatedBy;

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "updatedAt": { readonly: true, label: "Actualizado" },
            "updatedBy": { readonly: true, label: "Actualizado por" },
            "createdAt": { readonly: true, label: "Creado" },
            "createdBy": { readonly: true, label: "Creado por" },
        }
        return result;
    }

    getLabelFromFieldName(name) {
        console.log('name of field', name)
        let arrayFields = this.getArrayFields();
        let result = null;
        if (arrayFields[name] != null) {
            result = arrayFields[name].label;
        }
        if (result == null) {
            result = name;
        }
        return result;
    }

    isThisValueFromTypified(name) {
        let result = false
        if (this.isThisTypeExist(name)) {
            result = true
        }
        return result;
    }

    isThisTypeExist(type) {
        let listTypes = {
            "orderStatus": "orderStatus",
            "diagnosis": "diagnosis",
            "assetType": "assetType",
            "assetSubType": "assetType",
            "assetCodes": "assetCodes",
            "symptom": "symptom",
            "status": "status",
            "quality": "quality",
            "workOrderType": "workOrderType",
            "subType": "subType",
            "interventionCategory": "interventionCategory",
            "zone": "zone",
            "manufacturer": "manufacturer",
            "manuref": "manuref",
            "type": "type",
            "assetId": "assetId",
            "model": "model",
            "relationship": "relationship",
            "chainPos": "chainPos",
            "rolUser": "rolUser",
            "statusDispatcherWorkOrder": "statusDispatcherWorkOrder",
            "articleFamily": "articleFamily",
            "articleType": "articleType",
            "statusArticles": "statusArticles",
            "articleSubfamily": "articleSubfamily",
            "appTask": "appTask",
            "causesPause": "causesPause",
            "emploiments": "emploiments",
            "employeeCapacitation": "employeeCapacitation",
            "groups": "groups",
            "locales": "locales",
            "statusAssets": "statusAssets",
            "timeZones": "timeZones",
            "chainId": "chainId",
            "typeIntervention": "typeIntervention",
            "vehicles": "vehicles",
        };
        let result = false;
        this.log({ typeExist: 1, type });
        if (listTypes[type] != null) {
            result = true;
        }
        return result;
    }

    getResponseFieldsFromMutation() {
        return ["id", "createdAt", "createdBy", "updatedAt", "updatedBy"];
    }


    addRelatedTableDocuments(result, childType) {
        if (this._relatedTables["documents"]) {
            result["documents"] = {
                type: "Relation", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTablePerson(result, childType) {
        if (this._relatedTables["person"]) {
            result["person"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTableSchedulePerson(result, childType) {
        if (this._relatedTables["schedulePersons"]) {
            result["schedulePersons"] = {
                type: "Relation", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTableLocation(result, childType) {
        if (this._relatedTables["location"]) {
            result["location"] = {
                type: "RelationOne", foreignKey: "locationId", onlyRead: true, childType: childType
            }
        }
    }


    addRelatedTableZoneAsigned(result, childType) {
        if (this._relatedTables["zoneAsigned"]) {
            result["zoneAsigned"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }

    addRelatedTableEmployment(result, childType) {
        if (this._relatedTables["employmentUser"]) {
            result["employmentUser"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }


    addRelatedTableRelationship(result, childType) {
        if (this._relatedTables["relationship"]) {
            result["relationship"] = {
                type: "RelationOne", onlyRead: true, childType: childType,
            }
        }
    }


}
