import React, { Component } from 'react';
import withTranslate from "../../translator/withTranslate";
import AppLogger from "../../util/AppLogger";

class GqlErrors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: this.props.errors?.slice(0, 4),
            copiaErrors: this.props.errors || []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors !== this.props.errors) {
            this.setState({
                errors: nextProps.errors.slice(0, 4),
                copiaErrors: nextProps.errors || [],
            })
        }
    }

    render() {
        let props = this.props;
        const { t, i18n } = props;
        if (this.state.errors == null) {
            return null;
        }
        if (this.state.errors?.length === 0) {
            return null;
        }
        return (
            <div className="alert alert-danger justify-content-between gql-errors-main-css" role="alert">
                <div className={"column"}>
                    {this.state.errors.map((error, index) => (
                            <div key={index}>
                                {error.extensions?.data?.canBeTranslated === "1" ?
                                    <div>
                                        {t(error.message)}
                                    </div>
                                    :
                                    <div>
                                        {error.message}
                                    </div>
                                }
                            </div>
                        )
                    )
                    }
                </div>
                {this.state.errors?.length >= 4 &&
                <span className="text-click" style={{ paddingLeft: 20 }}
                      onClick={() => this.setState({ errors: [...this.state.copiaErrors] })}>
                {t("Ver #totalErrores errores").replace("#totalErrores", this.state.copiaClients?.length)}
                    <i className="fas fa-plus-circle" />
            </span>
                }
                <span className="text-click" style={{ paddingLeft: 20 }} onClick={() => this.resetError()}>
                <i className="fas fa-times" />
            </span>
            </div>
        )

    }


    resetError() {
        this.props.setErrors([]);
        this.setState({ errors: [] })
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

};


export default withTranslate(GqlErrors);
