import BaseModelState from "./BaseModelState";
import { observable } from "mobx";
import PersonModel from "../models/PersonModel";


class PersonState extends BaseModelState {

    @observable persons = [];

    async loadAllPersons() {
        let personModel = new PersonModel();
        this.persons = await personModel.find();
    }
}

export default PersonState;
