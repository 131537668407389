import React from 'react';
import { observer } from 'mobx-react';
import GroupOfField from "../components/layout/GroupOfField";
import PageTitle from "../components/PageTitle"
import { withApollo } from "react-apollo";
import BaseForm from "./base/BaseForm";
import FormWrapper from "../components/FormWrapper";
import FormButtons from "../components/FormButtons";
import NetworkStatusInfo from "../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../components/status/LoadingOrErrorInfo";
import withTranslate from "../translator/withTranslate";
import PropsUtil from "../util/PropsUtil";
import TabsComponent from "../components/fields/TabsComponent";
import TabsData from "../layout/TabsData";
import UploadFileComponent from "../components/UploadFileComponent";
import util from "../util/Util";
import PageTitleRightModal from "../components/modals/PageTitleRightModal";
import InfoBlock from "../components/InfoBlock";
import UserModel from "../models/UserModel";
import MoreButtons from "../components/MoreButtons";
import { toJS } from "mobx";
import GqlErrors from "../components/status/GqlErrors";
import SponsorModel from "../models/SponsorModel";
import LocationModel from "../models/LocationModel";
import PersonModel from "../models/PersonModel";
import ScheduleModel from "../models/ScheduleModel";
import ExhibitorModel from "../models/ExhibitorModel";
import DocumentModel from "../models/DocumentModel";

@observer
class PageDocument extends BaseForm {
    nameMainType = this.props.model;

    constructor(props) {
        super(props);
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
    }

    getModelQuery() {
        let modelos = {
            "user": new UserModel(),
            "sponsor": new SponsorModel(),
            "location": new LocationModel(),
            "person": new PersonModel(),
            "schedule": new ScheduleModel(),
            "exhibitor": new ExhibitorModel(),
        };
        let result = modelos[this.nameMainType];
        result.addRelatedTable("documents");
        return result;
    }

    async componentDidMount() {
        await super.componentDidMount();
    }

    getModelQueryForView() {
        let modelos = {
            "user": new UserModel(),
            "sponsor": new SponsorModel(),
            "location": new LocationModel(),
            "person": new PersonModel(),
            "schedule": new ScheduleModel(),
            "exhibitor": new ExhibitorModel(),
        };
        let result = modelos[this.nameMainType];
        result.addRelatedTable("documents");
        return result;
    }

    tabsDocument() {
        let tipos = {
            "user": this.tabsData.getDataUser(),
            "sponsor": this.tabsData.getDataSponsor(),
            "person": this.tabsData.getDataPerson(),
            "location": this.tabsData.getDataLocation(),
            "schedule": this.tabsData.getDataSchedule(),
            "exhibitor": this.tabsData.getDataExhibitor(),
        };
        return tipos[this.nameMainType];
    }

    async uniqueUpload(documentAdd) {
        let documentModel = new DocumentModel();
        documentModel.hidrate(documentAdd);
        documentModel.modelName = this.getModelQuery().getNameModelInDotNetTables();
        documentModel.modelId = this.graphDataMainType.id;
        this.graphDataMainType.documents = [documentModel];
    }


    renderImpl() {
        const { t, i18n } = this.props;
        let id = this.getFormId() || this.props.id;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        let model = this.graphDataMainType;
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={id}
                                tabs={this.tabsDocument()}
                                fromRightModal={this.props.fromRightModal}
                                active={"Documentos"}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                            />
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={id}
                            tabs={this.tabsDocument()}
                            active={"Documentos"}
                        />
                        }
                        <div className="card-body card-body--nopadd">

                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}
                            >
                                <div className={'card mb-3'}>

                                    <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                       error={this.graphStatus.mutationError}
                                                       working={this.graphStatus.networkWorking}
                                    />
                                    <LoadingOrErrorInfo formLoading={this.graphStatus.formLoading}
                                                        formError={this.graphStatus.formError}
                                    />
                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

                                    <FormWrapper>

                                        <GroupOfField title={t('Documentacion')}
                                                      icon="fas fa-book"
                                        >

                                            <div className={"col-12"}>
                                                <div className={"row"}>
                                                    <UploadFileComponent
                                                        title={t("Subida de Archivos")}
                                                        required={true} model={model}
                                                        name={'uploadFiles'}
                                                        onChangePrevious={() => this.onChangePrevious()}
                                                        onChange={(e) => this.uniqueUpload(e)}
                                                        classGroup={"col-12"}
                                                        documents={model.documents}
                                                        baseFolder={this.nameMainType + "/" + this.getFormId() + "/documents"}
                                                    />
                                                </div>
                                            </div>
                                        </GroupOfField>
                                    </FormWrapper>
                                </div>
                                <FormButtons id={id} formStatus={this.graphStatus} />
                            </form>
                        </div>
                    </div>
                </InfoBlock>
            </div>


        )
    }

    onChangePrevious() {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
    }
}

export default withTranslate(withApollo(PageDocument));
