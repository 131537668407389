import React, { Component } from 'react';
import { observer } from 'mobx-react';
import util from '../util/Util';
import { now } from "moment";
import withTranslate from "../translator/withTranslate";

@observer
class PageTitle extends Component {
    constructor(props) {
        super(props);
    }



    render() {
        let props = this.props;
        const { t, i18n } = this.props;
        return (
            <div className="title-section">
                <div className="row">
                    <div className="height-titles col-12 text-left d-md-flex align-items-md-center">
                        <h1 className="title title--inline">{props.title}</h1>
                        <h2 className={this.props.toLowerCase ? "text-lowercase subtitle " : "subtitle "}>{props.subtitle}</h2>
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
export default withTranslate(PageTitle);

