import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";
import LocationModel from "./LocationModel";
export default class ExhibitorModel extends AbstractModel {

    title;
    description;
    tag;
    locationId;
    numeration;
    nameMainType = "exhibitor";
    graphFindByIdOperation = "exhibitor";
    graphFindOperation = "exhibitorsConnection";
    graphExportOperation = "exhibitorsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "title": "",
            "description": "",
            "tag": "",
            "locationId": "",
            "numeration": "",
        };
        this.addRelatedTableLocation(result, LocationModel);
        this.addRelatedTableDocuments(result, DocumentModel);
        return result;
    }


}
