import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Sidebar from "./sidebar";
import appState from "../state/AppState";
import CreateNew from "./CreateNew";
import VsfLoadingBar from "../network/VsfLoadingBar";
import UserButton from "./UserButton";
import RightModals from "../components/modals/RightModals";
import InfoBlockSwitch from "../components/InfoBlockSwitch";
import VsfLink from "../components/VsfLink";
import withTranslate from "../translator/withTranslate";
import AppLogger from "../util/AppLogger";
import { observable } from "mobx";
import version from '../static_data/version';


@observer
class AppWraper extends Component {

    @observable user = {};

    constructor() {
        super();
        this.state = { "sidebarOpened": false }
    }

    clickHeaderToogler() {
        document.body.classList.add('header-hidden');
        document.body.classList.remove('header-minimized');
        this.setState({ "sidebarOpened": !this.state.sidebarOpened });
    }

    async componentDidMount() {
        open = true;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


    render() {
        let mail = localStorage.getItem('mail');
        const { t, i18n } = this.props;
        let props = this.props;
        let classApp = "";
        if (appState.layoutState.sidebarMinimized) {
            classApp = "App web-body header-minimized sidebar-minimized";
        } else {
            classApp = "App web-body";
        }
        //Si no estoy logado no muestro sidebar
        if (!appState.loginState.isUserLogged) {
            return (
                <React.Fragment>
                    {props.children}
                </React.Fragment>
            )
        }
        return (
            <div className={classApp}>

                <header className="web-header">
                    <div className="web-header__left">
                        <VsfLink to="" className="header-brand">
                            <div className="red-square" />
                            <img src="/img/logo.png" alt="Nuddo" className="header-brand-full" />
                            <img src="/img/logo-small.png" alt="Nuddo" className="header-brand-minimized" />
                        </VsfLink>
                    </div>
                    <div className="web-header__right">
                        <button className={"header-minimizer sidebar-minimizer"}
                                onClick={() => appState.layoutState.clickHiddenMenu()}>
                            <i className="fa fa-bars"></i>
                        </button>
                        <div className="web-header__right__end">
                            <CreateNew />
                            <div className="header-user dropdown">
                                <UserButton
                                    userInfo={() => this.getUserHeader(mail)}
                                />
                                {appState.layoutState.userDropMenuOpen &&
                                <div className="dropdown-menu dropdown-menu-right" style={{ "display": "block" }}>
                                    <div className="dropdown-header text-center">
                                        <strong>Account</strong>
                                    </div>
                                    <VsfLink className="dropdown-item" to="/profile/form">
                                        <i className="fa fa-user"></i>{t('Perfil')}</VsfLink>
                                    <div className="divider"></div>
                                    <VsfLink className="dropdown-item" to="/change/password">
                                        <i className="fa fa-shield"></i>{t('Cambiar contraseña')}</VsfLink>
                                    <VsfLink className="dropdown-item" onClick={(e) => {
                                        appState.loginState.doLogout();
                                    }}>
                                        <i className="fa fa-lock"></i>{t('Cerrar Sesion')}</VsfLink>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </header>

                <div className="web-main">
                    <Sidebar />
                    <main className="main">
                        <div className="container-fluid">
                            <RightModals />
                            {props.children}
                        </div>
                    </main>
                </div>

                <footer className="web-footer">

                    <nav className="footer-nav ml-auto">
                        <ul className="nav">
                            <li><a href="#">{t("Politica de Privacidad")}</a></li>
                            <li><a href="#">{t("Aviso legal")}</a></li>
                            <li><a href="#">{t("Terminos de uso")}</a></li>
                        </ul>
                    </nav>
                </footer>
                <VsfLoadingBar />
                <InfoBlockSwitch />
            </div>
        );
    }

    getUserHeader(mail) {
        const { t, i18n } = this.props;
        return (
            <React.Fragment>
            <span className="user-info">
                <span className="user-name">
                    {t("Usuario")}
                </span>
                <span className="user-job">
                    {mail}
                </span>
            </span>
            </React.Fragment>
        )
    }

}

//withRouter es necesario para que AppWrapper pueda estar dentro de <Router> siendo @observable
export default withTranslate(withRouter(AppWraper));

