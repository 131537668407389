import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import VsfButton from "./VsfButton";
import PropTypes from 'prop-types';
import appState from "../state/AppState";
import Overlay from "./modals/Overlay";
import withTranslate from "../translator/withTranslate";
import util from '../util/Util'


@observer
class MoreButtons extends Component {

    static propTypes = {
        actions: PropTypes.array,
    };
    static defaultProps = {
        actions: []
    };

    constructor() {
        super();
        this.state = {
            loading: false,
            lastModif: util.getMoment(Date.now()).format("YYYY-DD-MM HH:mm")

        }
    }

    async onReloadData() {
        this.setState({ loading: true, lastModif: util.getMoment(Date.now()).format("YYYY-DD-MM HH:mm") });
        await this.props.onReloadData();
        setTimeout(() => {
            this.setState({ loading: false });
        }, 500)

    }

    onClick(evento) {
        appState.layoutState.menu = false;
        evento();
    }

    render() {
        const { t, i18n } = this.props;
        return (
            <>
                <Overlay show={appState.layoutState.menu} onClick={_ => appState.layoutState.menu = false} />
                {
                    <>
                        {this.props.onReloadData &&
                        <div id="last-update-text" className="wrapper-title-section-text col-12 col-lg-6 d-lg-flex align-items-lg-end">
                            <p className="title-section-text">{t("Última actualización") + ": " + this.state.lastModif}</p>
                        </div>
                        }
                        <div
                            className="wrapper-buttons-right col-12 col-lg-6 text-right d-flex justify-content-end align-items-end">
                            {this.props.onReloadData &&
                            <button type="button"
                                    className={this.state.loading ? "c-spinner-rotate focus" : "c-spinner-rotate"}
                                    aria-labelledby="last-update-text"
                                    onClick={() => this.onReloadData()} />
                            }
                            {this.props.actions.length > 0 &&
                            <VsfButton
                                onClick={() => appState.layoutState.clickMenu()}
                                classButton={"btn--points"}
                                icon={"fas fa-ellipsis-v"}
                                disabled={false}
                            />
                            }
                            {appState.layoutState.menu &&
                            <div className="dropdown-sublist" style={{ zIndex: 100 }}>
                                {this.props.actions.map((accion, index) => (
                                    <div className="dropdown-sublist__item ">
                                        <a onClick={() => this.onClick(accion.onClick)}>{t(accion.title)}
                                            <span>{accion.icon && <i className={accion.icon} />}</span></a>
                                    </div>
                                ))}
                            </div>
                            }
                        </div>
                    </>
                }
            </>
        );
    }

}

export default withTranslate(withRouter(MoreButtons));

