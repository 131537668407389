import React, { Component } from 'react';
import appState from "../state/AppState";

class ErrorLogin extends Component {


    render() {
        return (
            <div className="login-error js-login-error mb-3 p-3" >
                <i className="fa fa-exclamation-circle"> </i>
                <span>{appState.loginState.errroLogin}</span>
            </div>
        );
    }
}

export default ErrorLogin;

