import AbstractModel from "./AbstractModel";
export default class PageModel extends AbstractModel {

    id;
    icon;
    text;
    content;

    nameMainType = "page";
    graphFindByIdOperation = "page";
    graphFindOperation = "pagesConnection";
    graphExportOperation = "pagesExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "id": "",
            "icon": "",
            "text": "",
            "content": "",
        };
        return result;
    }


}
