class Config {

    /**
     * Indica si estamos configurados como "REAL"
     */
    isLive = false;
    analyticsCode = "XXXXX";


    constructor() {
        this.isLive = true;

        this.apiHost = 'http://localhost:5500';
        // this.apiHost = 'https://eventoswebtest.vsf.es';
        this.apiHostBaseUrl = this.apiHost + '/graphql';
        this.apiRestHostBaseUrl = this.apiHost + '/api';

        if (this.isLive) {
            if (process && process.env) {
                if (process.env.REACT_APP_API_GRAPHQL_ENDPOINT) {
                    this.apiHostBaseUrl = process.env.REACT_APP_API_GRAPHQL_ENDPOINT;
                }
                if (process.env.REACT_APP_API_GRAPHQL_ENDPOINT) {
                    this.apiRestHostBaseUrl = process.env.REACT_APP_API_REST_ENDPOINT;
                }
            }
        }

    }


}


export default new Config();
