/**
 * Created by jberbel on 12/04/2021.
 */
import React from 'react';
import { observer } from 'mobx-react';
import FormInputWrapper from "./FormInputWrapper";
import BaseFieldComponent from "./BaseFieldComponent";
import PropTypes from 'prop-types';
import AppLogger from "../../util/AppLogger";
import util from '../../util/Util'

@observer
export default class InputTypeComponent extends BaseFieldComponent {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        readOnly: PropTypes.bool, // Muestra el campo editable, pero con fondo gris en modo read Only
        showView: PropTypes.bool, // Muestra el campo en modo solo texto
    };

    static defaultProps = {
        readOnly: false,
        showView: false
    };

    constructor(props) {
        super(props);
        this.state = { errorsClientValidation: [], value: this.props.value }
    }

    componentWillReceiveProps(props) {
        if (util.hasValue(props.value)) {
            this.setState({
                value: props.value
            })
        }
    }

    onBlurFunction(e) {
        this.setState({
            value: e.target.value
        });
        if (this.props.onBlur != null) {
            let obj = {
                target: {
                    value: this.state.value,
                    name: this.props.name
                }
            };
            this.props.onBlur(obj)
        } else {
            this.validateOnBlurFunction(e)
        }
    }

    //El autocomplete esta con new-password porque es lo unico que aceptan TODOS
    //los navegadores y la aplicacion no necesita que coja de cache nada
    render() {
        let props = this.props;
        let classNameInput = this.getClassNameInput();

        return (
            <FormInputWrapper
        classGroup={props.classGroup}
        name={props.name}
        postfix={this.props.postfix}
        prefix={this.props.prefix}
        errors={this.props.errors}
        errorsClientValidation={this.state.errorsClientValidation}
        title={this.props.title}
        required={this.props.required}
        info={this.props.info}
        hasNoErrors={this.props.hasNoErrors}
        forceMedia={this.props.forceMedia}
        {...props}
    >
        {this.props.showView ?
        <div>
        {props.value}
        </div>
        :
        <input
            value={this.state.value}
            autoComplete="new-password"
            className={classNameInput}
            name={props.name}
            placeholder={props.placeholder}
            onChange={e => this.validateOnChangeFunction(e)}
            readOnly={props.readOnly}
            type={props.type}
            onBlur={(e) => this.onBlurFunction(e)}
        />
        }
        {props.children}
    </FormInputWrapper>
    );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
