import React, { Component } from 'react';
import { observer } from "mobx-react/index";
import withTranslate from "../translator/withTranslate";

@observer
class PageNoData extends Component {


    render() {
        const { t } = this.props;
        return (
            <section className="container-fluid align-items-center login">
                <h1 className="row justify-content-center">
                    {t("Este página no tiene datos")}
                </h1>
                <h3>
                    <a className={"text-click"} onClick={this.props.history.goBack}>{t("Volver")}</a>
                </h3>
            </section>

        );
    }
}

export default withTranslate(PageNoData);
