import LoginState from "./LoginState";
import TypifiedState from "./TypifiedState";
import LayoutState from "./LayoutState";
import LoadingBarState from "./LoadingBarState";
import UserState from "./UserState";
import MenuState from "./MenuState";
import PersonState from "./PersonState";
import PageState from "./PageState";
import SponsorState from "./SponsorState";
import LocationState from "./LocationState";
import ScheduleState from "./ScheduleState";
import SchedulePersonState from "./SchedulePersonState";
import ExhibitorState from "./ExhibitorState";


class AppState {
    constructor() {
        this.loginState = new LoginState();
        this.typifiedState = new TypifiedState();
        this.layoutState = new LayoutState();
        this.loadingBarState = new LoadingBarState();
        this.userState = new UserState();
        this.menuState = new MenuState();
        this.personState = new PersonState();
        this.pageState = new PageState();
        this.sponsorState = new SponsorState();
        this.exhibitorState = new ExhibitorState();
        this.locationState = new LocationState();
        this.scheduleState = new ScheduleState();
        this.schedulePersonState = new SchedulePersonState();
    }
}

export default new AppState();
