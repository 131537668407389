import {action, computed, observable} from 'mobx';
import FetchProxy from "../network/FetchProxy";
import config from "../config/config";
import AppLogger from "../util/AppLogger";
import appState from "./AppState";
import util from "../util/Util";
import LoadTranslations from "../translator/LoadTranslations";

class LoginState {

    @observable accessToken = window.localStorage.getItem('accessToken');
    @observable userId = window.localStorage.getItem('userId');
    @observable refreshToken = window.localStorage.getItem('refreshToken');
    @observable user = {};
    @observable loginButtonLoading = false;
    @observable errroLogin = "";
    @observable dataUser = {};

    @computed get isUserLogged() {
        let result = false;
        let accessToken = this.accessToken;
        if (accessToken !== "" && accessToken != null) {
            result = true;
        }
        return result;
    }

    @computed get getAccessToken() {
        return this.accessToken;
    }

    getUserId() {
        return this.userId;
    }

    async getUser() {
        // this.log({ getUser: 1, userId: this.userId, user: this.user });
        let result = null;
        if (util.hasValue(this.userId)) {
            if (this.user && this.user.id != null) {
                result = this.user;
            } else {
                result = await appState.userState.getUserById(this.userId);
                this.user = result;
            }
        }
        return result;
    }

    async doLogin() {
        let httpApi = new FetchProxy();
        try {
            this.loginButtonLoading = true;
            let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/token",
                {
                    "Email": this.dataUser.email,
                    "Password": this.dataUser.password,
                }
            );
            let responseJson = await response.json();
            // this.log({ doLogin: 1, responese: responseJson.data });
            if (responseJson.errorCode === 0) {
                this.loadAccessTokenInfo(responseJson.data);
                let user = await this.getUser();
                if (user.id != null) {
                    await appState.typifiedState.loadMasters();
                }
                if (user.timeZone != null) {
                    if (util.hasValue(appState.typifiedState.getObject(user.timeZone).code)) {
                        window.localStorage.setItem('currentTimeZone', appState.typifiedState.getObject(user.timeZone).code);
                    } else {
                        window.localStorage.setItem('currentTimeZone', 'Europe/Madrid');
                    }
                } else {
                    window.localStorage.setItem('currentTimeZone', 'Europe/Madrid');
                }
                if (user.locale != null) {
                    if (util.hasValue(appState.typifiedState.getObject(user.locale).code)) {
                        window.localStorage.setItem('currentLanguage', appState.typifiedState.getObject(user.locale).code);
                    } else {
                        window.localStorage.setItem('currentLanguage', 'es');
                    }
                    let loadTranslations = new LoadTranslations();
                    await loadTranslations.loadMessages();
                } else {
                    window.localStorage.setItem('currentLanguage', 'es');
                }
            } else {
                this.log(responseJson);
                this.errroLogin = responseJson.message;
            }
        } catch (e) {
            this.log({doLoginException: 1, e});
            this.errroLogin = "Se ha producido un error. Por favor vuelva a intentarlo pasados unos minutos";
        }
        this.loginButtonLoading = false;
    }

    @action
    doLogout() {
        window.localStorage.setItem('accessToken', "");
        window.localStorage.setItem('refreshToken', "");
        window.localStorage.setItem('userId', "");
        window.location.href = "/";
    }

    loadAccessTokenInfo(jsonData) {
        this.accessToken = jsonData.access_token;
        this.accessTokenExpiration = jsonData.access_token_expiration;
        this.refreshToken = jsonData.refresh_token;
        this.userId = jsonData.user_id;
        //No son async porque pueden hacerse en el background
        window.localStorage.setItem('accessToken', this.accessToken);
        window.localStorage.setItem('accessTokenExpiration', this.accessTokenExpiration);
        window.localStorage.setItem('refreshToken', this.refreshToken);
        window.localStorage.setItem('userId', this.userId);
    }

    async doRefreshToken() {
        let httpApi = new FetchProxy();
        try {
            if (this.refreshToken != "") {
                let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/refresh",
                    {"RefreshToken": this.refreshToken}
                );
                let responseJson = await response.json();
                this.loadAccessTokenInfo(responseJson);
            }
        } catch (e) {
            this.log({doRefreshTokenException: 1, e});
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default LoginState;
