import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { dir } from "async";
import GroupOfField from "../layout/GroupOfField";
import VsfButton from "../VsfButton";
import withTranslate from "../../translator/withTranslate";

@observer
class PopUp extends Component {


    render() {
        let { title, subtitle, icon, onClick1, onClick2, onClick3, loading1, text, label1, label2, label3 } = this.props;
        const { t, i18n } = this.props;
        return (
            <div className={this.props.classGroup}>
                <div className="modal-head">
                    <div className="row">
                        <GroupOfField
                            title={title}
                            subtitle={subtitle}
                            icon={icon}
                        />
                    </div>
                </div>
                <div className="modal-body">
                    {this.props.spinner &&
                    <div className=' text-center'>
                        <i className="fas fa-spinner fa-pulse" />
                    </div>
                    }
                    <div className=' text-center'>
                        <p className="modal-center-text">{text}</p>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className='d-md-flex text-right'>
                        {label3 &&
                        <VsfButton
                            label={label3}
                            onClick={() => onClick3()}
                            classGroup={'button-form-group mr-md-3'}
                            classButton="btn btn-secondary"
                        />
                        }
                        {label1 &&
                        <VsfButton
                            label={label1}
                            loading={loading1}
                            onClick={() => onClick1()}
                            classButton="btn--loader"
                            classGroup={'button-form-group mr-md-3'}
                        />
                        }
                        {label2 &&
                        <VsfButton
                            classButton="btn--loader"
                            label={label2}
                            onClick={() => onClick2()}
                            classGroup={'button-form-group'}
                        />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslate(PopUp);







