import React, { Component } from 'react';
import VsfModal from "../components/modals/VsfModal";
import { observer } from "mobx-react";

@observer
export default class AlertModal extends Component {


    onCloseModal() {
        if (this.props.onCloseModal != null) {
            this.props.onCloseModal();
        } else {
            this.props.modalState.modalOpen = false;
        }
    }

    onSaveButton() {
        this.props.listComponent.onClickSaveQuickEdit();
    }

    render() {
        return (
            <VsfModal
                stateFilter={this.props.modalState}
                isOpen={this.props.isOpen}
                className="top-to-bottom"
                onCloseModal={() => this.onCloseModal()}
            >
                {this.props.children}
            </VsfModal>
        )
    }
}
