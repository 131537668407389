import React from 'react';
import LoadTranslations from "./LoadTranslations";
import util from "../util/Util";
import AppLogger from "../util/AppLogger";
import TranslationsModel from "../models/TranslationsModel";

let loadTranslations = new LoadTranslations();

function withTranslate(WrappedComponent, page = "") {

    const TranslatedComponent = (props) => {

        let t = function (message) {
            let messages = loadTranslations.getMessages();
            let result = message;
            let userLocale = window.localStorage.getItem('currentLanguage');
            let currentLanguage;
            if (util.hasValue(userLocale)) {
                currentLanguage = userLocale;
            } else {
                currentLanguage = "es"
            }
            // log({ translates: 1, messages, message, trad: messages[limpiaString(message)] });
            //Tenemos mensaje traducido
            if (message != null && messages[limpiaString(message)] != null) {
                //messages[message] cuando haya traducciones
                result = messages[limpiaString(message)];
                if (util.esVacio(messages[limpiaString(message)])) {
                    result = message;
                }
            } else {
                if (message != null) {
                    let translation = new TranslationsModel();
                    translation.groupTranslation = "";
                    translation.label = message;
                    translation.message = "";
                    translation.lang = currentLanguage;
                    translation.device = TranslationsModel.WEB;
                    loadTranslations.setMessages(message, message);
                    translation.persist();
                }
                result = message;
            }
            return result;
        };


        function log(msg) {
            AppLogger.get().debug(msg, this);
        }

        function limpiaString(archivo) {
            archivo = archivo.replace(/[áàâãªä]/gi, "a");
            archivo = archivo.replace(/[ÁÀÂÃÄ]/gi, "a");
            archivo = archivo.replace(/[éèêë]/gi, "e");
            archivo = archivo.replace(/[ÉÈÊË]/gi, "e");
            archivo = archivo.replace(/[íìîï]/gi, "i");
            archivo = archivo.replace(/[ÍÌÎÏ]/gi, "i");
            archivo = archivo.replace(/[óòôõºö]/gi, "o");
            archivo = archivo.replace(/[ÓÒÔÕÖ]/gi, "o");
            archivo = archivo.replace(/[úùûü]/gi, "u");
            archivo = archivo.replace(/[ÚÙÛÜ]/gi, "u");
            archivo = archivo.replace(/[ñÑ]/gi, "n");
            archivo = archivo.replace(/ /gi, "");
            archivo = archivo.toLowerCase();
            // log({ limpiaString: 2, archivo });
            return archivo.toLowerCase();
        }

        return (
            <WrappedComponent t={t} {...props}>{props.children}</WrappedComponent>
        )

    };

    return TranslatedComponent;
}

export default withTranslate;
