let singleton = null;

class AppLogger {

    onlyDebug = [
        "RightModals"
    ];

    /**
     * @returns {AppLogger}
     */
    static get() {
        if (singleton == null) {
            singleton = new AppLogger();
        }
        return singleton;
    }

    info(msg, classObj) {
        this.debug(msg, classObj);
    }

    debug(msg, classObj) {
        let className = "[]";
        if (classObj) {
            className = classObj.constructor.name;
        }
        let message = "[" + className + "] ";
        if (typeof msg === 'string') {
            message += msg;
            consoleapp.log(message);
        } else {
            consoleapp.log(message + " =>");
            consoleapp.log(msg);
        }
    }

}


export default AppLogger;
