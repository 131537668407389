import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";

export default class SponsorModel extends AbstractModel {

    name;
    title;
    content;
    showInHome;
    type;
    orderView;
    description;

    nameMainType = "sponsor";
    graphFindByIdOperation = "sponsor";
    graphFindOperation = "sponsorsConnection";
    graphExportOperation = "sponsorsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "name": "",
            "title": "",
            "content": "",
            "showInHome": "",
            "type": "",
            "orderView": "",
            "description": "",
        };
        this.addRelatedTableDocuments(result, DocumentModel);
        return result;
    }


}
