import AbstractModel from "./AbstractModel";
import SchedulePersonModel from "./SchedulePersonModel";
import LocationModel from "./LocationModel";
import DocumentModel from "./DocumentModel";

export default class ScheduleModel extends AbstractModel {

    title;
    date;
    hourFrom;
    hourTo;
    timeZone;
    type;
    tag;
    locationId;
    description;
    nameMainType = "schedule";
    graphFindByIdOperation = "schedule";
    graphFindOperation = "schedulesConnection";
    graphExportOperation = "schedulesExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "title": "",
            "date": "",
            "hourFrom": "",
            "type": "",
            "timeZone": "",
            "hourTo": "",
            "description": "",
            "locationId": "",
            "tag": "",
        };
        this.addRelatedTableSchedulePerson(result, SchedulePersonModel);
        this.addRelatedTableLocation(result, LocationModel);
        this.addRelatedTableDocuments(result, DocumentModel);
        return result;
    }


}
