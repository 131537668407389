import React, { Component } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import apoloClient from "../storage/ApoloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import { gql } from "apollo-boost/lib/index";
import { observer } from "mobx-react/index";
import appState from "../state/AppState";
import util from "../util/Util";
import withTranslate from "../translator/withTranslate";
import AppLogger from "../util/AppLogger";
import Overlay from "./modals/Overlay";
import { now } from "moment";
import DocumentModel from "../models/DocumentModel";

let baseurl = "http://localhost:5000/api/document";

let documentAddQuery = async(fileKey) => {
    let query = gql`
        query DocumentAdd($fileKey:String){
            documentAdd(fileKey:$fileKey) {
                presignedURL,
                url,
                urlSource
            }
        }
    `;
    let variables = {
        fileKey: fileKey
    };

    let apolloProxy = new ApolloProxy(apoloClient);
    let resultQuery = await apolloProxy.graphQuery({ query, variables });
    let newData = resultQuery;
    return newData;
};

const images = require.context('../../public/img/fileicons', true);

const imagePath = (name) => images(name, true);


registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

@observer
class UploadFileComponent extends Component {

    constructor(props) {
        super(props);
        this.pond = React.createRef();

        this.state = {
            files: [],
            select: "",
        };
    };

    handleInit() {
        this.pond.current._pond.on("addfile", (error, file) => this.onAddedFile(error, file));
    }

    onAddedFile(error, file) {
        if (error) {
            return;
        }
        // this.log({ addfile: 1, file })
        // Set file metadata here in order to retrieve it in the custom process function
        // file attributes like fileExtension and filenameWithoutExtension
        // are not availabe in the file object in the custom process function
        file.setMetadata('fileInfo', {
            filenameWithoutExtension: file.filenameWithoutExtension,
            fileExtension: file.fileExtension
        });
    }

    getDocuments() {
        let result = [];
        if (this.props.documents != null) {
            result = this.props.documents;
        }
        return result;
    }

    addDocument({ name, url, urlSource, size }) {

        window.setTimeout(() => {
            let documentToAdd = { name, url, urlSource, size };
            if (this.props.documents && this.props.onChange == null) {
                this.props.documents.push(documentToAdd);
            }
            this.onChange(documentToAdd);
        }, 2000);
    }

    onChange(documentToAdd) {
        if (this.props.onChange != null) {
            this.props.onChange(documentToAdd);
        }
        if (this.props.onChangePrevious != null) {
            this.props.onChangePrevious();
        }
        appState.layoutState.formWithoutChanges = false;
    }

    checkReadOnly() {
        let readOnly = false;
        // if (this.props.model && !this.props.model?.editable) {
        //     readOnly = true;
        // }
        return readOnly;
    }

    deleteDocument = (index) => () => {
        this.props.documents.splice(index, 1);
        if (this.props.onChangePrevious != null) {
            this.props.onChangePrevious();
        }
        appState.layoutState.formWithoutChanges = false;
    };


    getImageThumbnail(document) {
        let result = "";
        if (util.hasValue(document.url)) {
            let extensionesValidas = [];
            extensionesValidas.push(".png");
            extensionesValidas.push(".gif");
            extensionesValidas.push(".jpeg");
            extensionesValidas.push(".jpg");
            let isImage = false;
            extensionesValidas.map((extension) => {
                if (document.url.toLowerCase().includes(extension.toLowerCase())) {
                    isImage = true;
                }
            });
            if (isImage) {
                result = document.url;
            } else {
                let iPath;
                try {
                    iPath = imagePath('./' + document.url.split('.').pop() + '.png');
                } catch (err) {
                    /*No encuentra un icono específico con el formato del archivo subido*/
                    iPath = this.getDefaultIconPath();
                }
                result = iPath;
            }
        }
        return result;
    };

    /*Devuelve una ruta de un icono genérico que mostramos por defecto cuando no tenemos un icono para el formato del documento*/
    getDefaultIconPath() {
        return imagePath('./undefined.png');
    }

    renderImage(path) {
        return <img src={path} />;
    }


    render() {
        let documents = this.getDocuments();
        let { t } = this.props;
        let props = this.props;
        let optionsSelectFile = [
            { label: "Back-End", value: "back-End", },
            { label: "Technical", value: "technical", },
            { label: "Video", value: "video", }
        ];
        let optionsSelectPhoto = this.props.optionsSelect || [
            { label: "Back-End", value: "back-End" },
            { label: "Technical", value: "technical", },
            { label: "Photo", value: "photo", },
            { label: "Video", value: "video", }
        ];
        let classGroup = props.classGroup;
        if (props.configForm) {
            classGroup = 'row'
        }
        let readOnly = this.checkReadOnly();
        return (
            <div className={classGroup} style={{ flex: 1 }}>
                <div className={props.configForm ? "col-6" : "col-12"}>
                    {!readOnly &&
                    <FilePond
                        ref={this.pond} onprocessfiles={() => this.onprocessfiles()}
                        acceptedFileTypes={['image/*', 'application/pdf', 'application/doc', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                        server={this.getServer()} labelTapToUndo={""} labelFileProcessingComplete={t("Archivo subido")}
                        labelFileProcessing={t("Subiendo archivo")} labelTapToCancel={""}
                        oninit={() => this.handleInit()}
                        labelIdle={t('Arrastre los ficheros aquí') + '</br>o</br>' + '<span class="filepond--label-action">' + t('Añadir ficheros') + '</span>'}
                        allowMultiple={true}
                    />
                    }

                    <label htmlFor={props.name}
                           className="control-label">
                        {props.title}
                    </label>
                </div>
                <div className="uploaded-elements-container">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        {documents.map((document, index) => {
                            return (
                                <div key={index} className="col-3 col-xl-2">
                                    <div className="fileItem">
                                        {!readOnly &&
                                        <div className="fileItem-header" title={document.name}>
                                        <span onClick={this.deleteDocument(index)} className="delete-file"><i
                                            className="fa fa-trash-alt"> </i></span>
                                            <a className="download-file" target="_blank" href={document.url}>
                                                <span>{document.name}</span>
                                            </a>
                                        </div>
                                        }
                                        <div className="fileItem-content">
                                            <div>
                                                <a className="download-file" target="_blank" href={document.url}>
                                                    {this.renderImage(this.getImageThumbnail(document))}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="fileItem-footer">
                                            {document.createdAt ?
                                                <span>{util.getDateWithoutHours(document.createdAt)}</span>
                                                :
                                                <span>{util.getMoment(now()).format("DD/MM/YYYY")}</span>
                                            }
                                            <span className="ml-1 text-click"
                                                  onClick={() => {
                                                      if (!readOnly) {
                                                          this.setState({ select: document.urlSource })
                                                      }
                                                  }}>{document.field || "document"}
                                                <span
                                                    className="fas fa-angle-down" />
                                        </span>
                                            <span className="size">{util.formatBytes(document.size)}</span>
                                        </div>
                                    </div>
                                    {this.state.select === document.urlSource &&
                                    <>
                                        <Overlay show={this.state.select == document.urlSource}
                                                 onClick={() => this.setState({ select: false })} />
                                        <div className="dropdown-sublist" style={{ zIndex: 100 }}>
                                            {this.isImage(document) ?
                                                <>
                                                    {optionsSelectPhoto.map((option, index) => (
                                                        <div className="dropdown-sublist__item ">
                                                            <a onClick={() => this.onChangeFileType(option, document)}>{t(option.label)}</a>
                                                        </div>
                                                    ))}
                                                </>
                                                :
                                                <>
                                                    {optionsSelectFile.map((option, index) => (
                                                        <div className="dropdown-sublist__item ">
                                                            <a onClick={() => this.onChangeFileType(option, document)}>{option.label}</a>
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        </div>
                                    </>
                                    }
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        )
            ;
    }

    isImage(documentJS) {
        let document = new DocumentModel();
        document.hidrate(documentJS);
        return document.isImage();
    }


    onChangeFileType(obj, document) {
        document.field = obj.value;
        this.onChange(document);
        this.setState({ select: "" })
    }

    onprocessfiles() {
        setTimeout(() => {
            this.pond.current._pond.removeFiles();
        }, 1500);
    }

    getServer() {
        let me = this;
        let baseFolder = this.props.baseFolder;
        // let log = (msg) => this.log(msg);
        let log = () => {
        };
        let server = {
            url: baseurl,
            process: function (fieldName, file, metadata, load, error, progress, abort) {
                var filepondRequest = new XMLHttpRequest({
                    contentType: 'binary/octet-stream',
                    processData: false,
                });
                log({ fieldName, file, metadata, load, error, progress, abort });
                let fileKey = baseFolder + "/" + file.name;
                let signedResult = documentAddQuery(fileKey);
                signedResult.then((response) => {
                    log({ signedResult: 1, response });
                    try {
                        let presignedUrl = response.data.documentAdd.presignedURL;
                        let urlSource = response.data.documentAdd.urlSource;
                        let url = response.data.documentAdd.url;
                        var filepondFormData = new FormData();
                        filepondFormData.append("file", file);
                        filepondRequest.upload.onprogress = function (e) {
                            progress(e.lengthComputable, e.loaded, e.total);
                        };
                        filepondRequest.open("PUT", presignedUrl);
                        filepondRequest.onload = function () {
                            load(`${"file.name"}`);
                            log({ Loaded: 1, file });
                            me.addDocument({ name: file.name, url, urlSource, size: file.size });
                        };
                        let binaryFile = filepondFormData.get("file");
                        filepondRequest.send(binaryFile);
                    } catch (e) {
                        log("Error uploading file=>");
                        log({ e });
                    }
                })

            }
        };
        return server;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withTranslate(UploadFileComponent);
