import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import BaseForm from "../base/BaseForm";
import FormWrapper from "../../components/FormWrapper";
import FormButtons from "../../components/FormButtons";
import withTranslate from "../../translator/withTranslate";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import LoadingOrErrorInfo from "../../components/status/LoadingOrErrorInfo";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import { observable } from "mobx";
import PropsUtil from "../../util/PropsUtil";
import util from '../../util/Util';
import MoreButtons from "../../components/MoreButtons";
import InfoBlock from "../../components/InfoBlock";
import ErrosFromGql from "../../components/status/ErrosFromGql";
import AppLogger from "../../util/AppLogger";
import MenuModel from "../../models/MenuModel";
import Select2Component from "../../components/fields/Select2Component";
import appState from '../../state/AppState';
import AutoSuggestComponent from "../../components/fields/AutoSuggestComponent";
import PageModel from "../../models/PageModel";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import IconPickerComponent from "../../components/fields/IconPickerComponent";

@observer
class MenuForm extends BaseForm {

    nameMainType = "menu";
    @observable currentModalState = { modalOpen: false };

    constructor(props) {
        super(props);
        this.nameMainType = "menu";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        //Si este campo está relleno, lo busca a la hora de obtener el registro

        this.state = {
            errorFormulario: false,
            modalLoading: false,
        }
    }


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.pageState.loadAllPages();
        this.setState({ change: 1 })
    }

    getModelQuery() {
        return new MenuModel();
    }


    async getPagesSuggestions(value) {
        let pagesArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            // Obtenemos los Assets
            let pagesQuery = new PageModel();
            pagesQuery.filters = [
                { "fieldName": "text", "fieldValue": value, "filterOperator": "SUBSTR" },
            ];
            let pages = await pagesQuery.find();
            // Transformamos a formato Autosuggest
            pages.map((page) => {
                let pageObject = {};
                pageObject.label = page.text;
                pageObject.value = page;
                pagesArray.push(pageObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        return pagesArray;
    }

    getPageFromPageId(pageId) {
        let result = {};
        if (util.hasValue(pageId)) {
            for (let page of appState.pageState.pages) {
                if (page.id === pageId) {
                    result = page;
                    break;
                }
            }
        }
        return result;
    }

    getOptionsTypeMenu() {
        let result = [];
        result.push({ value: MenuModel.CODE_HTML, label: 'Pagina HTML' });
        result.push({ value: MenuModel.CODE_SCHEDULE, label: 'Agenda' });
        result.push({ value: MenuModel.CODE_SPONSOR, label: 'Sponsor' });
        result.push({ value: MenuModel.CODE_LOCATION, label: 'Localizacion' });
        result.push({ value: MenuModel.CODE_EXHIBITOR, label: 'Expositores' });
        result.push({ value: MenuModel.CODE_PERSON, label: 'Persona' });
        result.push({ value: MenuModel.CODE_EXTERNAL_URL, label: 'Url Externa' });
        // result.push({ value: MenuModel.CODE_NEW_PROJECT, label: 'Nuevo proyecto' });
        result.push({ value: MenuModel.CODE_SOCIAL_MEDIA, label: 'Social Media' });
        result.push({ value: MenuModel.CODE_MY_SCHEDULE, label: 'Mi agenda' });

        return result;
    }

    render() {
        const { t } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        let id = this.getFormId() || this.props.id;
        let menu = this.graphDataMainType;
        if (menu == null) {
            menu = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        if (this.graphStatus.mutationError > 0) {
            this.state.errorFormulario = true;
        }
        let modalClasses = util.getModalClasses();
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>

                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataMenu()}
                                active="Datos Generales"
                                alarm={2}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Menu")}
                            subtitle={(menu.title && menu.title) || t("Nuevo")}>
                            {this.graphDataMainType.id &&
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                                actions={
                                    [
                                        {
                                            title: "Crear Nuevo Menu",
                                            onClick: () => this.propsUtil.changeUrl("/menus/form/")
                                        },
                                        {
                                            title: "Eliminar Menu",
                                            onClick: () => this.setState({ rightModal: true, popUp: "eliminar" })
                                        },
                                    ]
                                }
                            />
                            }
                        </PageTitle>
                    }
                    <div className="card mb-3">
                        {!this.props.fromRightModal &&
                        <TabsComponent
                            id={this.graphDataMainType.id}
                            tabs={this.tabsData.getDataMenu()}
                            active={"Datos Generales"}
                            fromRightModal={this.props.fromRightModal}
                        />
                        }
                        <div className={modalClasses.body}>
                            <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                error={this.graphStatus.mutationError}
                                working={this.graphStatus.networkWorking}
                            />
                            <LoadingOrErrorInfo //formLoading={this.graphStatus.formLoading}
                                formError={this.graphStatus.formError} />
                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}>
                                <div className={'card mb-3'}>
                                    <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                        error={this.graphStatus.mutationError}
                                        working={this.graphStatus.networkWorking}
                                    />
                                    <LoadingOrErrorInfo //formLoading={this.graphStatus.formLoading}
                                        formError={this.graphStatus.formError}
                                    />
                                    <ErrosFromGql errorsMapped={errorsMapped} />
                                    <FormWrapper>
                                        <GroupOfField
                                            title={t('Datos.')}
                                            subtitle={t('Solicitud')}
                                            icon='fas fa-file-import'>
                                            <InputTypeComponent
                                                value={menu.title}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"title"}
                                                title={t("Título")}
                                                classGroup={"col-md-6 col-lg-4"}
                                                type={"text"}
                                                info={t('Nombre que mostrará el menú')}
                                                errors={errorsMapped.title} />
                                            <InputTypeComponent
                                                value={menu.icon}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"icon"}
                                                title={t("Icono")}
                                                classGroup={"col-md-6 col-lg-4"}
                                                type={"text"}
                                                info={t('')}
                                                errors={errorsMapped.icon} />
                                            <Select2Component
                                                value={menu.type}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"type"}
                                                title={t("Tipo")}
                                                clearable={false}
                                                classGroup={"col-md-4"}
                                                options={this.getOptionsTypeMenu()} />
                                            {this.graphDataMainType.type === MenuModel.CODE_HTML &&
                                            <AutoSuggestComponent
                                                value={this.getPageFromPageId(menu.pageId).text}
                                                onChange={(menuSelected) => {
                                                    if (menuSelected.id !== '') {
                                                        this.handleAutosuggestSelection("pageId", menuSelected.id);
                                                        menu.pageId = menuSelected.id;
                                                    } else {
                                                        menu.pageId = "";
                                                    }
                                                }}
                                                name={"pageId"}
                                                title={t("Pagina")}
                                                classGroup={"col-md-6 col-lg-4"}
                                                errors={errorsMapped.pageId}
                                                info={t('Titulo')}
                                                loadSuggestions={(value) => this.getPagesSuggestions(value)}
                                                placeholder={t("Escribe...")}

                                            />
                                            }
                                            {this.graphDataMainType.type === "EXTERNAL_URL" &&
                                            <InputTypeComponent
                                                value={menu.externalURL}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"externalURL"}
                                                title={t("URL externa")}
                                                classGroup={"col-md-6 col-lg-4"}
                                                type={"text"}
                                                info={t('URL publica externa')}
                                                errors={errorsMapped.externalURL} />
                                            }
                                            <InputTypeComponent
                                                value={menu.iconURL}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"iconURL"}
                                                title={t("Icono URL")}
                                                classGroup={"col-md-6 col-lg-4"}
                                                type={"text"}
                                                info={t('')}
                                                errors={errorsMapped.iconURL} />
                                            <InputTypeComponent
                                                value={menu.orderView}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"orderView"}
                                                title={t("Orden")}
                                                classGroup={"col-md-6 col-lg-4"}
                                                type={"int"}
                                                info={t('')}
                                                errors={errorsMapped.iconURL} />
                                        </GroupOfField>
                                    </FormWrapper>
                                </div>
                                <FormButtons id={id} formStatus={this.graphStatus} />

                            </form>
                        </div>
                    </div>
                    <AlertModal isOpen={this.state.rightModal && this.state.popUp != ""}
                                onCloseModal={() => this.setState({
                                    rightModal: false,
                                    popUp: ""
                                })}>
                        {this.state.popUp == "eliminar" &&
                        <PopUp
                            title={t('Eliminar')}
                            subtitle={t('Menu') + ' ' + menu.title}
                            icon="exclamationTriangleIcon"
                            text={t('¿Estas Seguro de Eliminar este Menu y todos sus datos relaccionados?')}
                            label1={'No eliminar'}
                            label3={'Eliminar'}
                            onClick1={() => this.setState({ rightModal: false, popUp: "" })}
                            onClick3={() => this.deleteModel('menu')}
                        >
                        </PopUp>
                        }
                    </AlertModal>

                </InfoBlock>
            </div>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(withTranslate(MenuForm));
