import React, { Component } from 'react';
import appState from '../state/AppState';
import { observer } from "mobx-react/index";
import VsfButton from "../components/VsfButton";
import VsfLink from "../components/VsfLink";
import AppLogger from "../util/AppLogger";
import ErrorLogin from "../components/ErrorLogin";

@observer
class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typePasswordInput: "password",
        };
    }

    async loginClick() {
        await appState.loginState.doLogin();
    }

    changeViewPassword() {
        if (this.state.typePasswordInput === "password") {
            this.setState({
                typePasswordInput: "text"
            })
        } else {
            this.setState({
                typePasswordInput: "password"
            })
        }
    }

    updateInputEventLogin(e) {
        const { value } = e.target;
        appState.loginState.dataUser.Email = value;
        let mail = appState.loginState.dataUser.email = value;
        localStorage.setItem('mail', mail);
    }

    updateInputEventPass(e) {
        const { value } = e.target;
        appState.loginState.dataUser.password = value;
    }


    render() {

        return (
            <section className="container-fluid align-items-center login">
                <div className="row justify-content-center align-items-center login-block">
                    <div className="blur-block" />
                    <form name="login" className="text-center login-form js-login-form" onSubmit={(e) => {
                        e.preventDefault();
                        return false;
                    }}>
                        <input type="hidden" name="op" value="login" />
                        <input type="hidden" id="_token" name="_token"
                               value="gEjMTpIPVEOHenacxFAZPv0UVhZ8h78M6VhS49C0" />
                        <input type="hidden" name="referer" value="" />
                        {appState.loginState.errroLogin &&
                        <ErrorLogin />
                        }
                        <div className="login-logo mb-3 ml-auto mr-auto" />

                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <span className="fas fa-envelope"> </span>
                                                    </span>
                            </div>
                            <input type="text"
                                   id={'user'}
                                   name="login"
                                   className="form-control"
                                   size="16"
                                   placeholder="E-mail"
                                   aria-label="Email"
                                   aria-describedby="basic-addon1"
                                   autoComplete="off"
                                   onChange={(e) => this.updateInputEventLogin(e)}
                            />
                        </div>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1"
                                                          onClick={() => this.changeViewPassword()}>
                                                        {this.state.typePasswordInput === "password" ?
                                                            <span className="fas fa-lock"> </span>
                                                            :
                                                            <span className="fas fa-unlock"> </span>
                                                        }
                                                    </span>
                            </div>

                            <input type={this.state.typePasswordInput}
                                   onChange={(e) => this.updateInputEventPass(e)}
                                   name="password"
                                   id={'password'}
                                   className="form-control"
                                   size="16"
                                   placeholder="Password"
                                   aria-label="Password"
                                   aria-describedby="basic-addon1"
                                   autoComplete="off" />
                        </div>
                        <div className="form-group custom-checkbox mt-3 mb-0">
                            <input type="checkbox" id="remember-access" tabIndex="0" />
                            <label htmlFor="remember-access" className="mb-0">Recordar datos de acceso</label>
                        </div>
                        <div className="form-group mt-3 mb-0">
                            <VsfButton
                                id={'loginButton'}
                                label={'Acceder'}
                                classButton="btn-block btn-lg btn-primary mt-0 mb-0 js-login-btn"
                                disabled={appState.loginState.loginButtonLoading}
                                onClick={() => this.loginClick()}
                                type="submit" />

                        </div>
                        <div className="form-group mt-2 mb-0 link-remember">
                            <VsfLink to={'/password/reset/'}>¿No recuerdas tu contraseña?</VsfLink>
                        </div>
                    </form>
                </div>
            </section>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default LoginPage;
