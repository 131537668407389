import AbstractModel from "./AbstractModel";

export default class TypifiedValueModel extends AbstractModel {

    static CODE_ZONE = "zone";
    static CODE_SPONSOR_TYPE = "sponsorType";
    static CODE_SCHEDULE_TYPE = "scheduleType";
    static CODE_PARTICIPATION_TYPE = "participationType";
    static CODE_RELATIONSHIP = "relationship";
    static CODE_USER_ROLE = "rolUser";
    static CODE_EMPLOIMENTS = "emploiments";
    static CODE_EMPLOYEE_CAPACITATION = "employeeCapacitation";
    static CODE_LOCALES = "locales";
    static CODE_TIME_ZONES = "timeZones";
    static CODE_TAGS = "tags";

    id;
    type;
    code;
    value;
    icon;
    image;
    color;
    config;

    nameMainType = "typifiedValue";
    graphFindByIdOperation = "typifiedValue";
    graphFindOperation = "typifiedValuesConnection";

    newModel() {
        return new TypifiedValueModel();
    }

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "type": "",
            "code": "",
            "color": "",
            "image": "",
            "value": "",
            "config": "",
            "parentId": "",
            "icon": { label: "Icono" },
        };
        return result;
    }

    /**
     * Dado un tipo de maestro obtiene el valor a mostrar
     * @param type
     * @returns {*}
     */

    getTextFromType(type) {
        let tipos = {
            "emploiments": "Puestos de Trabajo",
            "zone": "Zonas",
            "sponsorType": "Tipo de Sponsor",
            "scheduleType": "Tipo de Agenda",
            "participationType": "Tipo de Participacion",
            "relationship": "Relaciones de contactos",
        }
        let result = tipos[type];
        return result;
    }



}

