
export default class GraphException {

    constructor(objMessage) {
        if (objMessage instanceof String) {
            this.message=objMessage;
            this.errors=[{message:objMessage}];
        } else {
            if (objMessage!=null) {
                this.message=objMessage.message;
                this.errors=objMessage.errors;
            }
        }
    }

    getMessage() {
        let result=this.message;
        return result;
    }

    /**
     * A partir de una excepci�n de GraphQL devuelve un array con los errores. Puedesn ser GraphQL o otros
     * @param e Exception
     */
    getErrorsFromException(e) {
        let errors=[];
        console.log("e=>");
        console.log({e});
        if (e instanceof GraphException) {
            errors = e.errors;
        } else {
            console.log({e});
            let errorMessage={message:e.message + e.stack, stack:e.stack};
            errors=[errorMessage];
        }
        return errors;
    }

    /**
     * A partir del campo response.errors, obtiene los errores que se deben mostrar mapeados por "key"
     * @param response
     * @returns {{}}
     */
    getGraphErrorsFromStatus(response) {
        let errors = [];
        try {
            errors = response.errors;
        } catch (e) {
            this.log(e);
        }
        if (errors==null) {
            errors=[];
        }
        let errorsMapped={};
        for(let error of errors) {
            let key = "";
            try { key = error["extensions"]["data"]["field"]; } catch(e) {};
            if (errorsMapped[""+key]==null) {
                errorsMapped[""+key]=[];
            }
            errorsMapped[""+key].push(error);
        }
        return errorsMapped;
    }

}
