import GraphException from "./GraphException";
import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";

export default class ApolloProxy {

    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }

    /**
     * Lanza una query contra graphQL
     * Si devuelve error Timeout lanza una excepci�n como esta: {extensions:{data:{field:""}}, message:"Error al conectar con servidor"}
     * Si devuelve un error 400 (con datos nulos) devuelve los mensajes de error de esta forma
     * En caso de exito devuelve la respuesta Graphql
     * @returns {Promise<void>}
     */
    async graphQuery(queryParams) {
        let resultQuery = { data: null };
        let errorMessages = [{ message: "GraphException. Error al realizar llamada a servidor" }];
        try {
            resultQuery = await this.apolloClient.query(queryParams);
            console.log({ resultQuery });
        } catch (e) {
            if (e.networkError && e.networkError.statusCode == "401") {
                await appState.loginState.doLogout();
                window.location.href = "/";
            } else {
                let jsonObj = JSON.parse(JSON.stringify(e));
                //Si hay error graphQlResponse ser� null. Pero se podr� obtener desde message
                let graphQlResponse = await jsonObj.networkError ? jsonObj.networkError.result : null;
                if (graphQlResponse == null) {
                    //Si el servidor no se alcanza entrar� por aqu�:. Por ejemplo con "Network error: Failed to fetch"
                    // En otro caso ser� un mensaje error 400 con un listado de errores de GraphQL
                    errorMessages = [{ extensions: { data: { field: "" } }, message: jsonObj.message }];
                } else {
                    if (graphQlResponse.errors != null) {
                        errorMessages = graphQlResponse.errors;
                    }
                }
                throw new GraphException({
                    message: this.getErrorMessageFromErrors(errorMessages),
                    errors: errorMessages
                });
            }
        }
        // Si la query tiene errores, los muestro y lanzo excepción
        if (resultQuery.errors && resultQuery.errors.length > 0) {
            if (this.objetoSoloTienePropiedadesNull(resultQuery.data)) {
                throw new GraphException({
                    message: this.getErrorMessageFromErrors(resultQuery.errors),
                    errors: resultQuery.errors
                });
            }
        }
        if (resultQuery && resultQuery.data == null) {
            let graphDataException = { message: this.getErrorMessageFromErrors(errorMessages), errors: errorMessages };
            throw new GraphException(graphDataException);
        }
        return resultQuery;
    }

    objetoSoloTienePropiedadesNull(objeto) {
        let result = true;
        for ([key, value] of Object.entries(objeto)) {
            if (objeto[key] == null) {

            } else {
                result = false;
            }
        }
        return result;
    }


    async mutate(mutationParams) {
        let resultQuery = { data: null };
        let errorMessages = [{ message: "GraphException. Error processing request (001)" }];
        try {
            resultQuery = await this.apolloClient.mutate(mutationParams);
        } catch (e) {
            let jsonObj = JSON.parse(JSON.stringify(e));
            //Si hay error graphQlResponse será null. Pero se podrá obtener desde message
            let graphQlResponse = jsonObj.networkError.result;
            if (graphQlResponse == null) {
                //Si el servidor no se alcanza entrará por aquí:. Por ejemplo con "Network error: Failed to fetch"
                // En otro caso será un mensaje error 400 con un listado de errores de GraphQL
                errorMessages = [{ extensions: { data: { field: "" } }, message: jsonObj.message }];
            } else {
                if (graphQlResponse.errors != null) {
                    errorMessages = graphQlResponse.errors;
                }
            }
            throw new GraphException({ message: this.getErrorMessageFromErrors(errorMessages), errors: errorMessages });
        }
        if (resultQuery.data == null) {
            throw new GraphException({ message: "GraphException. Error processing request (003)", errors: [] });
        }
        return resultQuery;
    }

    getErrorMessageFromErrors(errorMessages) {
        let result = "";
        if (errorMessages.length > 0) {
            result = errorMessages[0].message;
            if (errorMessages.length > 1) {
                result += " and " + (errorMessages.length - 1) + " more";
            }
        } else {
            result = "GraphException. Error processing request (005)"
        }
        return result;

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
