export default class TabsData {

    getDataPerson() {
        let tabs = [
            {id:"100", name: "Datos Generales", label: "Datos Generales", value: "/person/form/", valuePage: "",rightModalTab: "general"},
            {id:"102", name: "Documentos", label: "Documentos", value: "/person/form/", valuePage: "/document",rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataMenu() {
        let tabs = [
            {id:"200", name: "Datos Generales", label: "Datos Generales", value: "/menu/form/", valuePage: "", rightModalTab: "menu"},
        ];
        return tabs;
    }

    getDataLocation() {
        let tabs = [
            {id:"300", name: "Datos Generales", label: "Datos Generales", value: "/location/form/", valuePage: "", rightModalTab: "general"},
            {id:"302", name: "Documentos", label: "Documentos", value: "/location/form/", valuePage: "/document",rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataSchedule() {
        let tabs = [
            {id:"400", name: "Datos Generales", label: "Datos Generales", value: "/schedule/form/", valuePage: "",rightModalTab: "general"},
            {id:"402", name: "Documentos", label: "Documentos", value: "/schedule/form/", valuePage: "/document",rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataExhibitor() {
        let tabs = [
            {id:"600", name: "Datos Generales", label: "Datos Generales", value: "/exhibitor/form/", valuePage: "",rightModalTab: "general"},
            {id:"602", name: "Documentos", label: "Documentos", value: "/exhibitor/form/", valuePage: "/document",rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataSponsor() {
        let tabs = [
            {id:"500", name: "Datos Generales", label: "Datos Generales", value: "/sponsor/form/", valuePage: "",rightModalTab: "general"},
            {id:"502", name: "Documentos", label: "Documentos", value: "/sponsor/form/", valuePage: "/document",rightModalTab: "document"},
        ];
        return tabs;
    }

    getDataUser() {
        let tabs = [
            {id:"700", name: "Datos Generales", label: "Datos Generales", value: "/user/form/", valuePage: "",rightModalTab: "general"},
        ];
        return tabs;
    }
}
