import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute, withRouter } from 'react-router-dom'
import appState from "../state/AppState";
import Overlay from "../components/modals/Overlay";
import VsfLink from "../components/VsfLink";
import withTranslate from "../translator/withTranslate";


@observer
class CreateNew extends Component {

    constructor() {
        super();
    }


    render() {
        const { t, i18n } = this.props;
        return (
            <div className="header-new">
                <button type="button" className="btn btn--new"
                        onClick={() => appState.layoutState.clickAddNew()}>{t('Crear nuevo')}
                </button>


                <Overlay show={appState.layoutState.menuCreateNewActive}
                         onClick={_ => appState.layoutState.menuCreateNewActive = false} />
                {appState.layoutState.menuCreateNewActive &&
                <React.Fragment>
                    <dl className="dropdown-sublist" style={{ zIndex: 100 }}>
                        <dt className="dropdown-sublist__head">{t('servicio')}</dt>
                        <dd className="dropdown-sublist__item">
                            <VsfLink onClick={_ => appState.layoutState.menuCreateNewActive = false}
                                     to={"/menu/form/"}>{t('Menu')}</VsfLink></dd>
                        <dd className="dropdown-sublist__item">
                            <VsfLink onClick={_ => appState.layoutState.menuCreateNewActive = false}
                                     to={"/person/form/"}>{t('Persona')}</VsfLink></dd>
                        <dd className="dropdown-sublist__item">
                            <VsfLink onClick={_ => appState.layoutState.menuCreateNewActive = false}
                                     to={"/sponsor/form/"}>{t('Sponsor')}</VsfLink></dd>
                        <dd className="dropdown-sublist__item">
                            <VsfLink onClick={_ => appState.layoutState.menuCreateNewActive = false}
                                     to={"/schedule/form/"}>{t('Agenda')}</VsfLink></dd>
                        {/*<dd className="dropdown-sublist__item"><a href="#">{t('Evento')}</a></dd>*/}
                        <dt className="dropdown-sublist__head">{t('otros')}</dt>
                        <dd className="dropdown-sublist__item">
                            <VsfLink onClick={_ => appState.layoutState.menuCreateNewActive = false}
                                     to="/user/form/">{t('Usuario')}</VsfLink>
                        </dd>

                    </dl>
                </React.Fragment>
                }

            </div>
        );
    }

}

//withRouter es necesario para que AppWrapper pueda estar dentro de <Router> siendo @observable
export default withTranslate(withRouter(CreateNew));

