import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withTranslate from "../../translator/withTranslate";
import PropsUtil from "../../util/PropsUtil";
import { withRouter } from "react-router-dom";
import AppLogger from "../../util/AppLogger";
import appState from '../../state/AppState';

class PageTitleRightModal extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        showClose: PropTypes.bool,
        hasEditOrView: PropTypes.bool,
        onCloseModal: PropTypes.func,
        onClickEdit: PropTypes.func,
        onClickView: PropTypes.func,
        mode: PropTypes.string,
    };
    static defaultProps = {
        title: "",
        subtitle: "",
        showClose: true,
        hasEditOrView: false,
        onCloseModal: () => {
        },
        onClickEdit: () => {
        },
        onClickView: () => {
        },
        mode: 'view',
    };

    componentDidMount() {
        if (this.props.onBackModal != null) {
            if (this.props.location.search != "") {
                this.log("arraySearch");
                this.log(this.props.location);
                let url = this.props.location.pathname + this.props.location.search;
                if (!appState.layoutState.arrayWithLocations.includes(url)) {
                    appState.layoutState.arrayWithLocations.push(url);
                }

                this.log(appState.layoutState.arrayWithLocations);
            }

        }
    }

    render() {
        const { t, i18n } = this.props;
        this.propsUtil = new PropsUtil(this.props);
        return (
            <div className="row">
                <div className="col-12 col-md-9 text-left">
                    <div className="modal-title">
                        <p>{t(this.props.title)} <span>{this.props.subtitle}</span></p>
                    </div>
                </div>
                <div className="col-12 col-md-3 d-flex justify-content-end">
                    {this.props.hasEditOrView ?
                        <>
                            {this.props.mode == 'view' &&
                            <button type="button" className="edit-modal" aria-label="Editar"
                                    onClick={() => this.props.onClickEdit()}>
                                <span className="far fa-edit" />
                            </button>
                            }
                            {this.props.mode == 'edit' &&
                            <button type="button" className="edit-modal" aria-label="Ver"
                                    onClick={() => this.props.onClickView()}>
                                <span className="far fa-eye" />
                            </button>

                            }
                        </>
                        :
                        this.props.showClose &&
                        <>{this.props.children}
                            <button type="button" className="close" aria-label="Cerrar"
                                    onClick={() => this.props.onCloseModal()}>
                                <span className="fas fa-times" />
                            </button>
                        </>}
                </div>

            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(withTranslate(PageTitleRightModal));
