import AbstractModel from "./AbstractModel";
export default class MenuModel extends AbstractModel {

    static CODE_HTML= "HTML";
    static CODE_SCHEDULE= "SCHEDULE";
    static CODE_NEW_PROJECT= "NEW_PROJECT";
    static CODE_SPONSOR= "SPONSOR";
    static CODE_LOCATION= "LOCATION";
    static CODE_EXHIBITOR= "EXHIBITOR";
    static CODE_PERSON= "PERSON";
    static CODE_EXTERNAL_URL= "EXTERNAL_URL";
    static CODE_SOCIAL_MEDIA = "SOCIAL_MEDIA";
    static CODE_MY_SCHEDULE = "MY_SCHEDULE";


    id;
    type;
    icon;
    pageId;
    parentId;
    personId;
    externalURL;
    personFilter;
    title;
    scheduleId;
    iconURL;
    orderView;

    nameMainType = "menu";
    graphFindByIdOperation = "menu";
    graphFindOperation = "menusConnection";
    graphExportOperation = "menusExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "id": "",
            "type": "",
            "scheduleId":"",
            "icon": "",
            "personId":"",
            "pageId": "",
            "title": "",
            "parentId": "",
            "externalURL": "",
            "personFilter": "",
            "iconURL": "",
            "orderView": "",
        };
        return result;
    }


}
