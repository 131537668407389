import React, { Component } from 'react';
import appState from '../state/AppState';
import { observer } from "mobx-react/index";
import FetchProxy from "../network/FetchProxy";
import VsfButton from "../components/VsfButton";
import { observable } from "mobx";
import AppLogger from "../util/AppLogger";
import config from "../config/config";
import VsfLink from "../components/VsfLink";

@observer
class PasswordReset extends Component {
    @observable loading = false;

    constructor(props) {
        super(props);
    }

    async onRecoveryPassworkd() {
        let httpApi = new FetchProxy();
        try {
            this.loading = true;
            let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/recovery",
                {
                    "Email": appState.loginState.dataUser.Email,
                }
            );
            let responseJson = await response.json();
            if (responseJson.errorCode == 0) {
                let respuesta = responseJson.data.access_token;
                this.log({ respuestaJson: 1, respuesta })
            } else {
                this.log(responseJson);
            }
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        setTimeout(() => {
            this.loading = false;
        }, 1000);
        setTimeout(() => {
            window.location.href = "/";
        }, 2500);
    }

    /**
     * Se pone un email pero en appstate hay un observable con parametros de password ya asignados
     * @param e
     */
    updateInputEventM = e => {
        const { value } = e.target;
        appState.loginState.dataUser.Email = value;
    };
    /**
     * No se usa en ningun lado
     * @param e
     */
    updateInputEventP = e => {
        const { value } = e.target;
        appState.loginState.dataUser.password = value;
    };


    render() {
        return (
            <section className="container-fluid align-items-center login">
                <div className="row justify-content-center align-items-center login-block">
                    <div className="blur-block" />
                    <form name="login" className="text-center login-form js-login-form">

                        {appState.loginState.errroLogin &&
                        <div className="login-error js-login-error mb-3 p-3">
                            <i className="fa fa-exclamation-circle" />
                            <span>{appState.loginState.errroLogin}</span>
                        </div>
                        }
                        <div className="login-logo mb-3 ml-auto mr-auto" />
                        <div className="login-intro text-center mb-3">
                            <p className="not-remember">No recuerdo mi contraseña.</p>
                            <p className="login-text">Escribe tu email y te enviaremos un correo con las instrucciones
                                para restablecer tu contraseña.</p>
                        </div>
                        <div className="input-group mb-3">
                            <input type="text"
                                   id={'email'}
                                   name="login"
                                   className="form-control"
                                   size="16"
                                   placeholder="E-mail"
                                   aria-label="Email"
                                   aria-describedby="basic-addon1"
                                   autoComplete="off"
                                   onChange={(e) => this.updateInputEventM(e)}
                            />
                        </div>
                        <div className="form-group mt-4 mb-0">
                            <VsfButton
                                label={'Enviar mensaje'}
                                loading={this.loading}
                                classButton="btn-block btn-lg btn-primary mt-0 mb-0 js-login-btn"
                                messageOnLoading={"Enviando"}
                                messageOnSuccess={"Enviado"}
                                onClick={() => this.onRecoveryPassworkd()}
                                messageOnFailure={"Error al enviar"}
                                type="button" />
                        </div>
                        <div className="form-group mt-2 mb-0 link-remember">
                            <VsfLink to={'/'}>Volver a Iniciar sesion</VsfLink>
                        </div>
                    </form>
                </div>
            </section>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default PasswordReset;
