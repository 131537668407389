import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
    DefaultRoute, Link
} from 'react-router-dom'
import VsfLink from "../components/VsfLink";


@observer
class SidebarSubItem extends Component {
    render() {
        let props = this.props;
        let title = props.title;
        return (
            <li className="nav-item--dropdown__item">
                <VsfLink className={this.props.active == true ? " active" : ""} to={this.props.href}>
                    {title}
                </VsfLink>
            </li>
        );
    }
}

export default SidebarSubItem;

