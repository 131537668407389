import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";

export default class LocationModel extends AbstractModel {

    icon;
    mapLongitude;
    mapLatitude;
    mapZoom;
    title;
    content;

    nameMainType = "location";
    graphFindByIdOperation = "location";
    graphFindOperation = "locationsConnection";
    graphExportOperation = "locationsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "icon": "",
            "mapLongitude": "",
            "mapLatitude": "",
            "mapZoom": "",
            "content": "",
            "title": "",
        };
        this.addRelatedTableDocuments(result, DocumentModel);
        return result;
    }


}
