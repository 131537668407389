import React, { Component } from 'react';
import withTranslate from "../../translator/withTranslate";
import CardDashboardComponent from "./CardDashboardComponent";
import PageTitle from "../../components/PageTitle";

class Dashboard extends Component {

    render() {
        const { t, i18n } = this.props;
        return (
            <div>
                <PageTitle
                    title={t("Panel de Control")}>
                </PageTitle>
                <div className="row">
                    <CardDashboardComponent
                        title={t('Menu')}
                        info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                        icon="fas fa-bars"
                        classGroup='col-md-4'
                        link='/menu/ls'
                        t={t}
                    />
                    <CardDashboardComponent
                        title={t('Paginas')}
                        info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                        icon="fas fa-file"
                        classGroup='col-md-4'
                        link='/pages/ls'
                        t={t}
                    />
                    <CardDashboardComponent
                        title={t('Personas')}
                        info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                        icon="fas fa-users"
                        classGroup='col-md-4'
                        link='/person/ls'
                        t={t}
                    />
                    <CardDashboardComponent
                        title={t('Sponsors')}
                        info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                        icon="fas fa-users"
                        classGroup='col-md-4'
                        link='/sponsor/ls'
                        t={t}
                    />
                    <CardDashboardComponent
                        title={t('Localización')}
                        info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                        icon="fas fa-map-marker-alt"
                        classGroup='col-md-4'
                        link='/location/ls'
                        t={t}
                    />
                    <CardDashboardComponent
                        title={t('Agenda')}
                        info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                        icon="fas fa-calendar"
                        classGroup='col-md-4'
                        link='/schedule/ls'
                        t={t}
                    />
                    <CardDashboardComponent
                        title={t('Usuarios')}
                        info={t("ext ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five")}
                        icon="fas fa-users"
                        classGroup='col-md-4'
                        link='/user/ls'
                        t={t}
                    />

                </div>

            </div>
        );
    }
}

export default withTranslate(Dashboard, 'Dashboard');
