import AbstractModel from "./AbstractModel";
import util from '../util/Util'
import * as mimeTypes from "react-native-mime-types";

export default class DocumentModel extends AbstractModel {


    id;
    size;
    url;
    urlSource;
    createdAt;
    name;
    field;
    modelName;
    modelId;
    thumbnailx1;
    thumbnailx2;
    thumbnailx4;
    thumbnailx8;
    thumbnailx16;

    nameMainType = "document";
    graphFindByIdOperation = "document";
    graphFindOperation = "documentsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "size": "",
            "modelName": "",
            "modelId": "",
            "field": "",
            "urlSource": "",
            "url": { readonly: true },
            "name": { readonly: true },
            "thumbnailx1": { readonly: true },
            "thumbnailx2": { readonly: true },
            "thumbnailx4": { readonly: true },
            "thumbnailx8": { readonly: true },
            "thumbnailx16": { readonly: true }
        };
        return result;
    }

    getUrl() {
        return this.url;
    }

    isImage() {
        let result = false;
        let mime = this.getMimeType();
        this.log({ isImage: 1, mime });
        if (mime.startsWith("image")) {
            result = true;
        }
        return result;
    }

    getMimeType() {
        let ext = util.getFileExtension(this.getUrl());
        this.log({ url: this.getUrl(), ext });
        let mimeType = mimeTypes.lookup(ext);
        let mimeTypeStr = util.getString(mimeType);
        return mimeTypeStr;
    }


}
