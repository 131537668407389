import React from 'react';
import { observer } from 'mobx-react';
import { DefaultRoute } from 'react-router-dom'
import BaseCommon from "./BaseCommon";
import GraphException from "../../network/GraphException";
import appState from "../../state/AppState";
import { computed, observable, toJS } from 'mobx';
import util from '../../util/Util';
import Links from "../../util/Links";
import './../../scss/components/form-show_history.scss';
import './../../scss/components/form-file_uploader.scss';
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import PageNoData from "../PageNoData";

@observer
class BaseForm extends BaseCommon {
    changeFormOrder = false;
    nameMainType = "";
    @observable changes = [];
    controller = null;
    signal = null;
    /**
     * Al cargar la información de base de datos la guardo aquí. De esa forma podré saber qué elementos mandar nuevos (solo los modificados)
     */
    modelPlainFirstLoaded = {};
    @observable appStateLocal = false;

    constructor(props) {
        super(props);
        this.queryFieldWithId = null;
    }

    @computed get graphDataMainType() {
        return this.graphData.data[this.nameMainType];
    }

    @computed get graphStateObj() {
        let stateObj = appState[this.nameMainType + "State"];
        if (stateObj == null) {
            throw "graphStateObj() The Form must has a State of the form appState[\"" + this.nameMainType + "State\"]";
        }
        return stateObj;
    }

    @computed get graphData() {
        return this.graphStateObj.graphData;
    }

    @computed get graphStatus() {
        return this.graphStateObj.graphStatus;
    }

    async componentDidMount() {
        await this.componentDidMountImpl();
    }

    async componentDidMountImpl() {
        await this.reloadForm();
    }

    async reloadData() {
        await this.reloadForm();
    }

    async startCounts(model) {
        if (this.nameMainType === "order") {
            if (util.hasValue(model.id)) {
                await appState.assetState.getCountSlotsFromOrder(model);
                await appState.assetState.countWorkOrdersNotAssigned(model);
            }
        } else if (this.nameMainType === "workOrder") {
            if (util.hasValue(model.id)) {
                await appState.assetState.getCountSlotsFromWorkOrder(model);
            }
        }
    }

    initializeGraphData(nameMainType) {
        let id = this.getFormId();
        if (!util.hasValue(this.graphData.data[nameMainType]) || this.graphData.data[nameMainType].id !== id) {
            this.graphData.data[nameMainType] = {};
        }
    }

    /**
     * Metodo que se activa al seleccionar una opcion del autosuggest
     * @param fieldName
     * @param suggestSelected
     */
    handleAutosuggestSelection(fieldName, suggestSelected) {
        appState.layoutState.formWithoutChanges = false;
        this.updateInput(fieldName, suggestSelected)
    };

    updateInputEvent(event, op) {
        this.changeFormOrder = true;
        appState.layoutState.formWithoutChanges = false;
        this.updateInput(event.target.name, event.target.value)
    }

    updateInputEventDynamicFields(name, value, group) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
        let modelFromState = this.graphDataMainType;
        let dynamicField = {};
        if (modelFromState.dynamicField) {
            dynamicField = JSON.parse(modelFromState.dynamicField);
        }
        if (util.hasValue(name)) {
            dynamicField[name] = value;
        }
        if (group != null) {
            if (dynamicField["groups"] != null) {
                if (!dynamicField["groups"].includes(group.code)) {
                    dynamicField["groups"].push(group.code);
                }
            } else {
                dynamicField["groups"] = [];
                dynamicField["groups"].push(group.code);
            }
        }
        // this.log({ updateInputEventDynamicFields: 1, dynamicField, group, modelFromState });
        modelFromState["dynamicField"] = JSON.stringify(dynamicField);
        appState.layoutState.formWithoutChanges = false;


    }

    getPageSubtitle() {
        const { t, i18n } = this.props;
        let tipos = {
            "asset": this.graphDataMainType.plate || t("Nuevo"),
            "person": this.graphDataMainType.name || t("Nueva"),
            "sponsor": this.graphDataMainType.title || t('Nuevo'),
            "location": this.graphDataMainType.title || t('Nuevo'),
            "schedule": this.graphDataMainType.title || t('Nuevo'),
            "exhibitor": this.graphDataMainType.title || t('Nuevo'),
        };
        return tipos[this.nameMainType];
    }
    getPageTitle() {
        const { t, i18n } = this.props;
        let tipos = {
            "sponsor": t("Sponsor"),
            "person": t("Persona"),
            "location": t("Localización"),
            "schedule": t("Agenda"),
            "exhibitor": t("Expositor"),
            "assetModel": t('Fabricante') + " " + this.graphDataMainType.manufacturer,
        };
        return tipos[this.nameMainType];
    }

    deleteInputEventDynamicFields(group) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
        let modelFromState = this.graphDataMainType;
        let dynamicField = {};
        if (modelFromState.dynamicField) {
            dynamicField = JSON.parse(modelFromState.dynamicField);
        }
        for (let field of group.dynamicField) {
            delete dynamicField[field.code]
        }
        if (dynamicField["groups"] != null) {
            let index = dynamicField["groups"].indexOf(group.code);
            if (index != -1) {
                dynamicField["groups"].splice(index, 1);
            }
        }
        if (dynamicField["groups"].length == 0) {
            delete dynamicField["groups"]
        }
        modelFromState["dynamicField"] = JSON.stringify(dynamicField);
        appState.layoutState.formWithoutChanges = false;

    }

    updateInput(key, value) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
        let modelFromState = this.graphDataMainType;
        modelFromState[key] = value;
        appState.layoutState.formWithoutChanges = false;
    }

    onBackModal() {
        appState.layoutState.arrayWithLocations.pop();
        this.props.history.push(appState.layoutState.arrayWithLocations.pop())
    }

    onCloseModal() {
        if (!appState.layoutState.formWithoutChanges && !appState.layoutState.loadingForm) {
            appState.layoutState.modalChangeOpen = true;
        } else {
            this.propsUtil.changeUrlRequest({
                fromRightModal: null,
                rightModal: null,
                articleId: null,
                rightModalTab: null,
                workOrderId: null,
                orderId: null,
                clientId: null,
                assetId: null,
                slotId: null,
            });
            appState.layoutState.arrayWithLocations = [];

        }
    }

    getGraphErrorsFromStatus() {
        let result = new GraphException().getGraphErrorsFromStatus(this.graphStatus.mutationGraphQlResponse);
        let client = this.graphDataMainType;
        if (client == null) {
            if (result[""] == null) {
                result[""] = [];
            }
            let error = { message: "Data error" };
            result[""].push(error);
        }
        return result;
    }

    /**
     * Lanza una query contra graphQL
     * Si devuelve error Timeout lanza una excepción como esta: {extensions:{data:{field:""}}, message:"Error al conectar con servidor"}
     * Si devuelve un error 400 (con datos nulos) devuelve los mensajes de error de esta forma
     * En caso de exito devuelve la respuesta Graphql
     * @returns {Promise<void>}
     */
    async graphQuery(queryParams) {
        let resultQuery = { data: null };
        try {
            resultQuery = await this.props.client.query(queryParams);
        } catch (e) {
            let errorMessage = {
                extensions: { data: { field: "" } },
                message: "GraphException.Error al conectar con servidor"
            };
            this.graphStatus.mutationGraphQlResponse.errors = [errorMessage];
            throw new GraphException({
                message: "GraphException.Error al conectar con servidor",
                errors: [errorMessage]
            });
        }
        if (resultQuery.data == null) {
            throw new GraphException({ message: "GraphException.Los datos obtenidos son null", errors: [{}] });
        }
        return resultQuery;
    }

    getModelQueryForView() {
        return this.getModelQuery();
    }

    getModelQuery() {
        return null;
    }

    async reloadForm() {
        let id = this.getFormId();
        this.graphStatus.formLoading = true;
        appState.loadingBarState.start();
        this.graphStatus.formError = false;
        this.graphStatus.mutationGraphQlResponse.errors = [];
        try {
            let modelQuery = this.getModelQueryForView();
            if (modelQuery != null) {
                let model;
                if (!util.hasValue(id)) {
                    model = this.getModelQueryForView();
                } else {
                    model = await modelQuery.findByIdNotNull(id);
                }
                if (!util.hasValue(model.id) && util.hasValue(id)) {
                    this.appStateLocal = true;
                } else {
                    this.appStateLocal = false;
                }
                this.modelPlainFirstLoaded = model.setAndGetInitialState();
                let modelPlain = Object.assign({}, model.toPlainObject())
                if (this.props.listOrderForm && !util.hasValue(this.props.order.id)) {
                    this.graphData.data[this.nameMainType] = this.props.order;
                } else {
                    this.graphData.data[this.nameMainType] = modelPlain;
                }
                await this.didReloadFormData(model);

                this.log({ reloadForm: 1, graphData: toJS(this.graphData.data[this.nameMainType]) });

            } else {
                this.log({ reloadFormElse: 1, EXCECPTION: 'getModelQuery is not defined' });
            }
        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e);
            this.graphStatus.mutationGraphQlResponse.errors = errors;
            this.log({ error: 1, e });
            if (util.hasValue(id)) {
                this.appStateLocal = true;
            }
        }
        appState.layoutState.formWithoutChanges = true;
        this.graphStatus.formLoading = false;
        appState.loadingBarState.finalize();
    }

    renderFormWithoutData() {
        return (
            <PageNoData {...this.props} />
        )
    }

    /**
     * Devuelve el id: String del formulario
     */
    getFormId() {
        let result;
        // Lo coje de queryString
        if (this.queryFieldWithId != null) {
            this.propsUtil = new PropsUtil(this.props);
            let identifier = this.propsUtil.getRequest(this.queryFieldWithId);
            result = identifier;
        }
        // Lo coje de props
        if (result == null) {
            result = this.props[this.queryFieldWithId];
        }
        if (result == null) {
            // Lo coge de la url /order/form/4
            let links = new Links();
            result = links.getFormId(this.props);
        } else {
            result = result + "";
        }
        return result;
    }

    async handleFormSubmit(e) {
        if (e) {
            e.preventDefault()
        }
        try {
            appState.loadingBarState.start();
            this.graphStatus.networkWorking = true;
            this.graphStatus.mutationError = false;
            this.graphStatus.mutationLoading = true;
            appState.layoutState.loadingForm = true;
            this.graphStatus.mutationGraphQlResponse = {
                errors: []
            };
            let modelPlain = toJS(this.graphDataMainType);
            let model = this.getModelQuery();
            model.hidrate(modelPlain);
            model.recoverInitialState(this.modelPlainFirstLoaded);
            let previousId = model.id;

            if (this.previousRowForDiscard != null) {
                await model.persist();
                // El campo id y el resto de campos necesarios los actualizo
                for (let fieldName of model.getResponseFieldsFromMutation()) {
                    this.graphDataMainType[fieldName] = model[fieldName];
                }
                this.modelPlainFirstLoaded = model.setAndGetInitialState();
                await this.formDidSave(model, previousId);
                this.previousRowForDiscard == null;
            }
            this.changeFormOrder = false;
        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e);
            this.graphStatus.mutationGraphQlResponse.errors = errors;
            this.graphStatus.mutationError = 1;
        }
        this.graphStatus.mutationLoading = false;
        appState.loadingBarState.finalize();
        setTimeout(() => this.graphStatus.networkWorking = false, 1000);
    }

    /**
     * The formulario has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {

    }

    saveOrderBeforeWorkOrder() {

    }

    /**
     * El componente ya ha cargado el tipo de datos principal
     * @returns {Promise<void>}
     */
    async didReloadFormData(model) {
        this.startCounts(model);
    }

    showHistory = () => {
        this.setState({
            historyVisible: !this.state.historyVisible
        });
    };

    showItemHistory = (index) => () => {
        let changes = this.state.changes;
        changes[index].visible = !changes[index].visible;
        this.setState({
            changes
        });
    };

    async deleteModel(nameList) {
        let modelo = this.getModelQuery();
        modelo.id = this.getFormId();
        try {
            await modelo.remove();
        } catch (e) {
            this.log("EXCEPCION=>");
            this.log(e);
        }
        return this.propsUtil.changeUrl("/" + nameList + "/ls/")

    }


    /**
     * Inicializa el AbortController con una nueva señal para poder cancelar las llamadas fecth
     */
    initializeAbortController() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    /**
     * Cancela las llamadas fetch que esten asignadas al AbortController
     */
    abortFetch() {
        if (this.controller) {
            this.controller.abort();
        }
    }

    render() {
        if (this.appStateLocal) {
            return this.renderFormWithoutData()
        } else {
            return this.renderImpl();
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default BaseForm;
