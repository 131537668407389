import BaseModelState from "./BaseModelState";
import { observable } from "mobx";

class ScheduleState extends BaseModelState {

    @observable schedulePersons = [];

}

export default ScheduleState;
