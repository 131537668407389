import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, toJS } from "mobx";
import { withApollo } from "react-apollo";
import withTranslate from "../../translator/withTranslate";
import withRouter from "react-router-dom/es/withRouter";
import RightModalInList from "../../subpages/RightModalInList";
import PropsUtil from "../../util/PropsUtil";
import PageDocument from "../../pages/PageDocument";
import appState from "../../state/AppState";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "./PopUp";

/**
 * Muestra las pestañas que nos haya solicitado a traves de las distintaws URLs de la aplicación
 */
@observer
class RightModals extends Component {

    @observable    gqlErrors = null;

    constructor(props) {
        super(props);

    }

    onCloseModal() {
        if (!appState.layoutState.formWithoutChanges && !appState.layoutState.loadingForm) {
            appState.layoutState.modalChangeOpen = true;

        } else {
            appState.layoutState.arrayWithLocations.pop();
            this.props.history.push(appState.layoutState.arrayWithLocations.pop())
            appState.layoutState.modalChangeOpen = false;
        }
        if (appState.layoutState.slotview == false) {
            appState.layoutState.backgroundScheduled = 0
        }
    }

    volver() {
        appState.layoutState.modalChangeOpen = false;

    }


    render() {
        this.propsUtil = new PropsUtil(this.props);
        let { t } = this.props;
        let props = this.props;

        return (
            <React.Fragment>
                {appState.layoutState.modalChangeOpen &&
                <AlertModal isOpen={appState.layoutState.modalChangeOpen}
                            onCloseModal={() => {
                                appState.layoutState.formWithoutChanges = false;
                                this.onCloseModal()
                            }}
                >
                    <PopUp
                        title={t('Has salido sin guardar')}
                        icon="exclamationTriangleIcon"
                        text={t('¿Quieres guardar los cambios? Los cambios se perderán si no lo guardas')}
                        label1={'Salir sin guardar'}
                        label2={'Volver'}
                        labelGuardar={true}
                        onClick1={() => {
                            appState.layoutState.formWithoutChanges = true;
                            this.onCloseModal()
                        }}
                        onClick2={() => {
                            this.volver()
                        }}
                    />
                </AlertModal>
                }
                <RightModalInList
                    isOpen={this.propsUtil.getRequest("rightModal") === "modalworkorderls"}
                    onCloseModal={() => this.onCloseModal()}
                >
                    {this.propsUtil.getRequest("rightModalTab") === "workorder" &&
                    <WorkOrderForm
                        key={this.propsUtil.getRequest("workOrderId")}
                        orderId={this.propsUtil.getRequest("orderId")}
                        id={this.propsUtil.getRequest("workOrderId")}
                        workOrderId={this.propsUtil.getRequest("workOrderId")}
                        dataInitial={toJS(this.workOrder)}
                        onCloseModal={() => this.onCloseModal()}
                        fromRightModal={true}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "historytech" &&
                    <WorkOrderHistoryTech
                        key={this.propsUtil.getRequest("workOrderId")}
                        orderId={this.propsUtil.getRequest("orderId")}
                        id={this.propsUtil.getRequest("workOrderId")}
                        workOrderId={this.propsUtil.getRequest("workOrderId")}
                        dataInitial={toJS(this.workOrder)}
                        onCloseModal={() => this.onCloseModal()}
                        fromRightModal={true}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "schedule" &&
                    <WorkOrderFormSlots
                        id={this.propsUtil.getRequest("workOrderId")}
                        workOrderId={this.propsUtil.getRequest("workOrderId")}
                        key={this.propsUtil.getRequest("workOrderId")}
                        dataInitial={toJS(this.workOrder)}
                        onCloseModal={() => this.onCloseModal()}
                        fromRightModal={true}
                    />
                    }

                    {this.propsUtil.getRequest("rightModalTab") === "articles" &&
                    <WorkOrderFormItems
                        id={this.propsUtil.getRequest("workOrderId")}
                        fromRightModal={true}
                        key={this.propsUtil.getRequest("workOrderId")}
                        dataInitial={toJS(this.workOrder)}
                        workOrderId={this.propsUtil.getRequest("workOrderId")}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "history" &&
                    <WorkOrderHistory
                        id={this.propsUtil.getRequest("workOrderId")}
                        workOrderId={this.propsUtil.getRequest("workOrderId")}
                        key={this.propsUtil.getRequest("workOrderId")}
                        fromRightModal={true}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                    {this.propsUtil.getRequest("rightModalTab") === "document" &&
                    <PageDocument
                        id={this.propsUtil.getRequest("workOrderId")}
                        workOrderId={this.propsUtil.getRequest("workOrderId")}
                        key={this.propsUtil.getRequest("workOrderId")}
                        fromRightModal={true}
                        orderId={this.propsUtil.getRequest("orderId")}
                        model="workOrder"
                        pestanya={"ordenes de trabajo"}
                        onCloseModal={() => this.onCloseModal()}
                        {...props}
                    />
                    }
                </RightModalInList>
            </React.Fragment>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default withRouter(withApollo(withTranslate(RightModals, "RightModals")));
